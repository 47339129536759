.main {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  width: 80vw;
  margin-top: 120px;
  margin-bottom: 100px;
  font-family: "Montserrat";
}

.input_label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 0 12px 0;
}

.input_field {
  border-radius: 25px;
  width: 100%;
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 10px;
  padding-right: 10px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

/* -------- Thumbnail --------- */
.thumbnail_area {
  /* width: 550px; */
  height: 125px;
  background: #F8FCFA 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: #00d498 dashed 1px;
  opacity: 1;
  margin-top: 28px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
  position: relative;
}

.upload_box {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.upload_box input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.upload_img {
  margin-bottom: 5px;
}

.file_upload {
  opacity: 0;
  cursor: pointer;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.upload_txt {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.support_txt {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #BDBDBD;
}
/* 
.thumbnail_pic {
  width: 550px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 5px;
} */

.thumbnail_pic {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  object-fit: cover;
}

.change_thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 30px;
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

.tooltip_icon{
  margin-left: 10px;
  height: 14px;
}

/* ---------- */

.textbox_field {
  border-radius: 25px;
  width: 100%;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.radio_label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-right: 10px;
}

.radio_div {
  margin-top: 10px;
}

input[type="radio"] {
  accent-color: #00a77f;
}

.price_div {
  display: flex;
}

.price_type_input {
  border-radius: 25px;
  width: 92px;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat";
  line-height: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  text-align: center;
}

.price_input {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 15px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat";
  line-height: 20px;
  box-sizing: border-box;
}

.btn_div {
  display: flex;
  gap: 0.5rem;
  margin-top: 15px;
  margin-left: auto;
  width: fit-content;
}

@media (max-width:500px){
  .main{
    margin-top: 13px;
    width: 93.5vw;
    margin-right: 13px;
    margin-left: 13px;
  }

  .thumbnail_area {
    height:90px;
    margin-top:15px;
  }
  
  .price_input {
    width: 50%;
    padding:20px 15px;
  }

  .price_type_input {
    width:50%;
    padding:20px 15px;
  }

  .radio_group{
    margin-top:17px;
  }

  .btn_div {
    margin-top:30px;
  }
}

@media (max-width:850px){
  .main{
    margin-top:20px;
  }
}