.dropdown_menu1 {
  width: 125px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  margin-left: -80px;
}

.dropdown_menu1 ul {
  list-style: none;
  padding: 1px 0px;
}

.dropdown_menu1 li {
  padding: 4px 12px;
}

.Hr3 {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.menu1 {
  text-align: left;
  font-size: 12px;
}

.menuList:hover {
  cursor: pointer;
}

.menuList{
  overflow: scroll;
}

.menuList::-webkit-scrollbar {
  display: none;
}