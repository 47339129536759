.main {
    max-width: 100%;
    /* margin-top: 90px; */
    font-family: Montserrat;
    /* border: 3px solid blue; */
    display: flex;
    flex-direction: column;
    scroll: none;

}
.container_full {
    display: flex;
    justify-content: center;
    width: 90% ;
    padding: 0px 1.5rem;
    /* border: 1px solid orangered; */
    margin: auto;
    max-height: 75vh;
}
.container_shrunk {
    width: 72%;
    margin-left: auto;
    padding: 0 1rem;
    /* border: 2px solid orangered; */
    max-height: 75vh;
}
.loader_style{
    text-align: center;
    margin-top: 200px
}
@media (max-width: 500px) {
    .main {
        max-width: 100%;
        width: 100%;
        overflow-x: hidden;
        min-height: 100vh;
    }
    .document_wrapper{
       max-width: 100%;
       max-height: 100%;
    }
    .container_full {
        padding: 0px;
        margin-top: 0px;
    }
}
