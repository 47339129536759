.subscriptionContainer {
  margin: 120px 250px 20px 250px;
}
.subscriptionBody {
  display: flex;
}
.subscriptionOptions {
  display: flex;
}
.subscriptionOptions > div {
  padding: 60px 50px;
  border-radius: 20px;
}
.heading {
  font-size: 40px;
}
.partition {
  border: 1px solid #bbbbbb;
  margin: 20px 0;
}
.optionsCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}
.optionsCards:nth-child(odd) {
  background-color: #f8f8f8;
  margin-right: 25px;
}
.optionsCards:nth-child(even) {
  background-color: #00d498;
  color: white;
  /* margin-left: 25px; */
}
.optionsCards:nth-child(odd) > div > div > hr {
  border: 4px solid #00d498;
  width: 30%;
}
.optionsCards:nth-child(even) > div > div > hr {
  border: 4px solid #ffffff;
  width: 30%;
}
.optionType {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.optionHeading {
  font-size: 30px;
  font-weight: 800;
}
.optionHeading > hr {
  margin: 20px 0 60px 0;
}
.pricing {
  text-align: right;
}
.pricing > div:nth-child(odd) {
  font-size: 24px;
  font-weight: 500;
}
.pricing > div:nth-child(even) {
  font-size: 18px;
}
.facilities > div {
  font-size: 16px;
  display: flex;
}
.facilities > div > div {
  margin-left: 15px;
  margin-bottom: 20px;
}
.facilities > div > img {
  height: 20px;
  width: auto;
}
.free {
  background-color: none !important;
}
.zoomBtn {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 60%;
}
.optionsCards:nth-child(odd) > button {
  background-color: transparent;
  color: #00d498;
  border: 1px solid #00d498;
}
.optionsCards:nth-child(odd) > button:hover {
  background-color: #00d498;
  color: #ffffff;
  border: 1px solid #00d498;
}
.optionsCards:nth-child(even) > button {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.optionsCards:nth-child(even) > button:hover {
  background-color: #ffffff;
  color: #00d498;
  border: 1px solid #ffffff;
}

/* ############################ Responsive 1520 ############################ */
@media only screen and (max-width: 1520px) {
  .subscriptionContainer {
    margin: 150px 200px 0 200px 60px;
  }
  .subscriptionOptions > div {
    padding: 50px 40px;
  }
  .heading {
    font-size: 35px;
  }
}
/* ############################ Responsive 1420 ############################ */
@media only screen and (max-width: 1420px) {
  .subscriptionContainer {
    margin: 120px 150px 0 150px;
  }
  .subscriptionOptions > div {
    padding: 45px 30px;
  }
}
/* ############################ Responsive 1250 ############################ */
@media only screen and (max-width: 1250px) {
  .subscriptionContainer {
    margin: 110px 100px 0 100px;
  }
  .subscriptionOptions > div {
    padding: 40px 30px;
  }
  .zoomBtn {
    width: 80%;
  }
}
/* ############################ Responsive 1090 ############################ */
@media only screen and (max-width: 1090px) {
  .subscriptionContainer {
    margin: 100px 80px 100px 80px;
  }
  .subscriptionOptions > div {
    padding: 40px 30px;
  }
  .subscriptionOptions {
    display: block;
  }
  .zoomBtn {
    width: 80%;
  }
}
/* ############################ Responsive 850 ############################ */
@media only screen and (max-width: 850px) {
  .subscriptionContainer {
    margin: 110px 80px 0 80px;
  }
  .subscriptionOptions > div {
    padding: 40px 30px;
  }
  .zoomBtn {
    width: 80%;
  }
  .optionsCards:nth-child(odd) {
    margin-right: 0px;
  }
  .optionsCards:nth-child(even) {
    margin-left: 0px;
  }
}
/* ############################ Responsive 550 ############################ */
@media only screen and (max-width: 770px) {
  .heading {
    font-size: 30px;
  }
  .subscriptionContainer {
    margin: 30px 20px 80px 20px;
  }
}
