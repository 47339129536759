.sale_page_container {
  display: flex;

  /* flex-direction: row; */
}

.sale_page_sidebar {
  flex: 1;
  /* background: red; */
  margin-left: 0;
  /* width: 350px; */
}

.parent_sale {
  flex: 4;
  margin-top: 120px;
  /* margin-left: 350px; */
  margin-right: 10px;
  margin-bottom: 50px;

  height: 100%;
}

.sale_top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sale_top > h1 {
  font-style: italic;
  font-weight: 700;
}

.listing_button {
  background-color: var(--primary-gradient);
  width: 15%;
  border: none;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
}

.sale_bottom {
  margin-top: 10px;
  display: grid;
  row-gap: 50px;

  column-gap: 20px;
  grid-template-columns: auto auto auto auto;
}

.sale_page_load_more_button {
  margin-top: 20px;
  display: flex;

  justify-content: center;
}

@media (max-width: 800px) {
  .sale_bottom {
    grid-template-columns: auto auto;
  }

  .parent_sale {
    margin-top: 50px;
    margin-left: 5px;
    margin-right: 5px;

    height: 100%;
    margin-bottom: 100px;
  }
}

@media (max-width: 600px) {
  .sale_bottom {
    grid-template-columns: auto auto;
  }

  .parent_sale {
    margin-top: 50px;
    margin-left: 5px;
    margin-right: 5px;

    height: 100%;
    margin-bottom: 100px;
  }
}

@media (max-width: 500px) {
  .sale_bottom {
    grid-template-columns: auto;
    justify-content: space-around;
  }

  .parent_sale {
    margin-top: 50px;
    margin-left: 5px;
    margin-right: 5px;

    height: 100%;
    margin-bottom: 100px;
  }
}

@media (max-width: 500px) {
  .sale_bottom {
    grid-template-columns: auto;
    justify-content: space-around;
  }

  .parent_sale {
    margin-top: 50px;
    margin-left: 5px;
    margin-right: 5px;

    height: 100%;
    margin-bottom: 100px;
  }
}
