.main_container {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
}
.back_btn {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
}
.feed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 130vh;
}
.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.form_container {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0px;
  margin: 25px 0;
  width: 100%;
  margin-bottom: 85px;
}
.input_label {
  font-size: 13px;
  /* margin: 0 10px; */
  /* padding-left: 1rem; */
  /* margin-left: 0px; */
  /* margin-top: 20px; */
  font-weight: normal;
}
.input_title_container {
  display: flex;
  align-items: center;
  font: normal normal 600 16px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  margin: 15px 0;
}
.lable_icon {
  margin-right: 10px;
}
.input_field {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  /* padding-left: 20px; */
  padding: 3px 4px 3px 22px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.input_field1 {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 105px;
  outline: none;
  padding: 17px 0px 15px 17px;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.attachment_icon_container {
  /* padding: 0px 15px; */
  display: flex;
  flex-direction: row;
}
.uploadBox input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
  position: relative;
}
.fileUpload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  /* top: 0; */
  /* left: 0; */
  /* bottom: 0; */
  /* right: 0; */
  width: 22px;
  /* height: 100%; */
}
.photo {
  /* transition: all .3s; */
  height: 22px;
  cursor: pointer;
}
.uploadBox:hover {
  filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg)
    brightness(95%) contrast(93%);
}

/* assigned to modal css */
.modal_container {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: fit-content;
  padding-top: 5px;
  padding: 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 90vh;
}
.modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.close_icon {
  cursor: pointer;
  width: 30px;
}
.max_due_container {
  display: flex;
  margin-top: 0px;
}

.lock_dueDate {
  /* color: yellow; */
  display: flex;
  margin: 16px 0;
  font-size: 14px;
  opacity: 0.7;
}
.lock_dueDate p {
  margin-left: 4px;
}

.checkDone {
  color: #00d498;
}
input[type="checkbox"] {
  accent-color: #00a77f;
}

.assign_btn_container {
  margin-top: 0px;
  float: right;
}
.date_time_container {
  display: flex;
  font-family: Montserrat;
}

/* discard modal */
.discard_modal_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: fit-content;
  padding-top: 5px;
  padding: 30px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 63vh;
}
.discard_modal_title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 0.95em;
}
.discard_modal_msg {
  margin: 25px 0px;
  font-size: 0.95em;
  letter-spacing: 0.1px;
  line-height: 22px;
}
.discard_btn {
  float: right;
  width: fit-content;
}

/* MEDIA */

@media (max-width: 1450px) {
  .feed_container {
    width: 120vh;
  }
}

@media (max-width: 960px) {
  .feed_container {
    width: 90%;
  }
  .modal_container {
    width: 90%;
  }
}
@media (min-width: 780px) and (max-width: 800px){
  .main_container {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 780px) {
  .main_container {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  .max_due_container {
    display: flex;
    flex-direction: column;
  }
  .modal_container {
    width: 85%;
  }
  .date_time_container {
    flex-direction: column;
  }
  .date_time_container > div {
    margin-bottom: 10px;
  }
  .back_btn{
    display: none;
  }
  .close_icon {
    width: 22px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@media (max-width: 510px) {
  .discard_modal_container {
    width: 83%;
  }
  .form{
    margin: 0 5px !important;
  }
  .form_container {
    box-shadow: none;
 
  }
}

@media (max-width: 380px) {
  .discard_modal_container {
    width: 76%;
  }
}
.form{
  margin: 0 30px;
}
input::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
.modal_form{
  margin: 0 15px;
}

.due_date_time_container{
  margin-left: 20px;
  margin-top: 0px;
}
@media (max-width: 780px) {
  .due_date_time_container {
    margin-left: 0px ;

  }
}
@media (max-height: 700px) {
  .input_title_container {
    font: normal normal 600 14px Montserrat;
  }
}

.loader_style{
  text-align: center;
   margin-top: 200px
}

.radio_group{
  /* margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center; */
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}

.tooltip_icon{
  margin-right: 14px;
}