.main_container {
  /* height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 90px; */
  /*  */
  margin-top: 90px;
  height: 100%;
  width: 100%;
}

.back_btn {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
}

.center_page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 1018px; /*140vh*/
  padding-top: 10px;
}

.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.student_header_container {
  display: flex;
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-radius: 10px;
  margin-top: 30px;
  width: 100%;
}

.student_name {
  margin-left: 20px;
}

.left_arrow {
  width: 12px;
  height: 24px;
  margin-right: 22px;
}

.right_arrow {
  width: 12px;
  height: 24px;
  margin-left: 22px;
}

.student_name_container {
  display: flex;
  align-items: center;
}

.submission_date {
  font-weight: 400;
  color: #858585;
}

.assignment_details_container {
  /* display: flex; */
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 200px;
  width: 100%;
}

.assignment_status {
  display: flex;
  justify-content: space-between;
  padding: 35px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}

.assignment_right_and_left {
  display: flex;
  width: 100%;
}

.assignment_details_left_container {
  /* margin-top: 30px; */
  padding: 15px;
  /* box-shadow: 0px 0px 20px 0px #3f3f411a; */
  flex-grow: 2;
  /* margin-right: 30px; */
  border-right: 1px solid #e5e5e5;
}

.assignment_details_right_container {
  /* margin-top: 30px; */
  padding: 0px 40px;
}

.attachment_container_div {
  margin-top: 15px;
}

.work_div {
  padding: 15px 0;
  /* box-shadow: 0px 0px 20px 0px #3f3f411a; */
}

.grade_container {
  margin: 20px 0;
}

.grade_and_save {
  display: flex;
  justify-content: space-evenly;
}
/* 
.grade_input {
  width: 55px;
  padding: 0px 10px;
} */

.grade_input_container {
  border-radius: 30px;
  border: 1px solid #a9a9a9;
  padding: 0 30px;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.grade_input {
  width: 35px;
  outline: none;
  font-size: 16px;
  text-align: right;
  font-weight: 400;
  border-bottom: 1px solid #a9a9a9;
  margin-right: 3px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.total_marks {
  margin-right: 40px;
}

.private_comment_input {
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid #a9a9a9;
  padding: 10px;
  outline: none;
  width: 92%;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}

.assignment_add_button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.assignment_save_button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.max_marks_text {
  margin-left: 5px;
  font-size: 16px;
}
.return_btn {
  margin-right: 20px;
}
.left_arrow_name {
  display: flex;
  align-items: center;
  min-width: 200px;
}
.comment_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
  margin-bottom: 12px;
}
.comment_container {
  margin: 6px;
  margin-top: 35px;
}
.comment_container_mobile {
  display: none;
}

@media (min-width: 800px) and (max-width: 1018px) {
  .main_container {
    margin-top: 94px !important;
  }
}

@media (max-width: 1018px) {
  .center_page_container {
    width: 95%;
  }
  .main_container {
    margin-top: 0;
  }
  .assignment_status {
    flex-direction: column;
    align-items: flex-start;
  }
  .assignment_status > span {
    margin-bottom: 10px;
  }
  .student_header_container {
    flex-direction: column;
  }
  .student_header_container > .student_name {
    width: 100%;
    margin: 0;
  }
  .student_name_container {
    justify-content: space-between;
    margin: 0 10px 10px 10px;
  }
  .student_header_container > .return_btn {
    display: flex;
    align-self: flex-end;
    margin-right: 10px;
  }
  .assignment_right_and_left {
    flex-direction: column;
  }
  .assignment_details_right_container {
    /* margin-top: 30px; */
    padding: 12px;
  }
  .grade_input_container {
    width: 100%;
  }
  .comment_container {
    display: none;
  }
  .comment_container_mobile {
    display: block;
    margin: 6px;
  }
  .back_btn{
    display: none;
  }
}
