.container {
    /* background-color: #29daed; */
    background-color: var(--secondary-main);
    color: #fff;
    font-weight: bold;
    margin-left: auto;
    padding: 12px;
    max-width: 80%;
    display: flex;
    word-wrap: break-word;
    margin-bottom: 7px;
    
}

.outerContainer {
    order: 2;
    display: flex;
    width: 100%;
}

.fileContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
    align-items: center;

    p {
        margin-bottom: 0;
    }
}