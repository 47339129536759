.mobileBottom {
  width: auto;
  height: 75px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #55555533;
  opacity: 1;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 20;
}

.bottom {
  top: 20px;
  display: flex;
  gap: 20px;
}

.bottom_container {
  text-align: center;
  display: grid;
  place-items: center;
}

.jobs_icon{
  filter: invert(49%) sepia(89%) saturate(361%) hue-rotate(102deg) brightness(90%) contrast(85%) !important;
}

.course_icon{
  filter: invert(74%) sepia(72%) saturate(398%) hue-rotate(7deg) brightness(102%) contrast(95%);
}

.image_name {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b4b4b4;
  opacity: 1;
}

.mobile_image {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 0.3;
  cursor: pointer;
}

@media (min-width: 1140px) {
  .mobileBottom {
    display: none;
  }
}

@media (max-width: 1099px) {
  .mobileBottom {
    display: flex;
  }
}

/* .chatMobileClass {
    display: block !important;
    top: 90px !important;
    bottom: 76px !important;
    left: 0px !important;
    background-color: #fff !important;
    width: 100% !important;
} */

/* language icon css */

.language_header{
  text-decoration: none;
  padding: 0px 0px 4px 0px;
  background-color: transparent;
  border-radius: 25px;
  color: white;

  width: 22px;
  /* height: 18px; */
  background: transparent 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.langTextDiv{
  align-items: center;
  margin: auto;
  /* text-transform:uppercase; */
  font: normal normal normal 13px/16px Montserrat;
  /* height: 16px */
}
.language_div{
  display: flex;
  cursor: pointer;
  position: relative;
}
.language_div_dark{
  display: flex;
  color: #b4b4b4;
  cursor: pointer;
  position: relative;
 
    text-align: center;
    display: grid;
    place-items: center;
  
}

.dropdownLanguage{
  width: 100px;
  height: 125px;
  box-shadow: 3px -1px 4px 2px #55555529;
  border-radius: 5px;
  position: absolute;
  bottom: 55px;
  /* left: -10px; */
  opacity: 1;
  background-color: #fff;
  z-index: 10;
}

.dropdownList{
  list-style: none;
  padding: 0;
  height: 93.6px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.inactiveList{
  margin-top: 6px !important; 
  color: #555555;
  cursor: "pointer";
  font-size: 15.5px
}

.activeList{
  margin-top: 6px !important; 
  color: #00D498;
  cursor: "pointer";
  font-size: 15.5px
}