.popupModalBox {
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    background: #31313150;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.hidePopupModalBox {
    display: none;
}

.publishCourse {
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 80%;
    position: relative;
    width: 50%;
    background: #fff;
    overflow-x: hidden;
    border-radius: 32px;
    z-index: 1000;
}

.hidePopupModalBox {
    display: none;
}

.verticalScrollbarContanier {
    overflow-y: scroll !important;
}

.verticalScrollbarContanier::-webkit-scrollbar {
    display: none;
}

#topHeaderImage {
    position: sticky;
    height: 220px;
}

.publishCourseHeading {
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.publishCourseHeading h4 {
    padding-left: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.courseType {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.courseType h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.courseType p {
    padding-left: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

#publishingTo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 30px 0 20px 0;
}

.inputTags {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 60px;
    width: 93%;
    border: 1px solid rgb(227, 223, 223);
    margin: 0 0 20px 10px;
    min-height: 42px;
    height: auto;
    padding: 0px 5px 0px 5px;
    overflow-x: scroll !important;
}

.inputTags::-webkit-scrollbar {
    display: none;
}

.course h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0 30px 0;
}

.course p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.courseDescription h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 30px 0 30px 0;
}

.courseDescription p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.coursePrice {
    display: flex;
    flex-direction: row;
    padding: 50px 0 30px 0;
}

.coursePrice h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.coursePrice p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #00D498;
    padding-left: 50px;
}

.courseFiles h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.courseButtons {
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.courseButtons #cbtn1 {
    margin-right: 15px;
    background: #FFFFFF;
    border: 1px solid #00D498;
    border-radius: 60px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #00D498;
    padding: 15px;
    width: 20%;
    cursor: pointer;
}

.courseButtons #cbtn2 {
    background: #00D498;
    border-radius: 60px;
    border: 1px solid #00D498;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 15px;
    width: 20%;
    cursor: pointer;
}

.courseFile {
    width: 98%;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

@media screen and (max-width: 952px) {

    .courseButtons{
        margin-top: 40px;
    }

    .courseButtons #cbtn1 {
        width: 26%
    }

    .courseButtons #cbtn2 {
        width: 26%
    }

    .publishCourse {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 60%;
        height: 70%;
    }

    .inputTags {
        height: auto;
        width: 90%;
        flex-wrap: wrap;
        margin-left: 5px;
    }

    #publishingTo {
        padding-left: 0;
    }

    .coursePrice {
        padding-top: 30px;
    }

    .publishCourseHeading{
        padding-top: 20px;
    }

    #topHeaderImage {
        height: 180px;
    }
}


@media screen and (max-width: 634px) {

    .publishCourseHeading img {
        width: 30px;
        height: 31px;
        padding-bottom: 0px;
    }

    .courseButtons{
        margin-top: 10px;
    }

    .publishCourseHeading {
        padding-top: 0px;
    }

    .publishCourseHeading h4 {
        padding-left: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    .publishCourse {
        padding-top: 0px;
        padding-bottom: 10px;
        width: 70%;
        border-radius: 12px;
    }

    .inputTags {
        width: 85%;
    }

    .courseFile {
        width: 93%;
    }

    .courseButtons #cbtn1 {
        margin-right: 7px;
        padding: 10px 7px 10px 7px;
    }

    .courseButtons #cbtn2 {
        padding: 10px 7px 10px 7px;
    }

}

@media screen and (max-width: 439px) {
    .publishCourse {
        width: 80%;
    }

    .inputTags {
        width: 80%;
    }

    .courseFile {
        width: 87%;
    }

    .courseButtons #cbtn1 {
        margin-right: 5px;
        width: 30%;
    }

    .courseButtons #cbtn2 {
        width: 30%;
    }

    .publishCourseHeading{
        padding-top: 0px;
    }

    #topHeaderImage {
        height: 170px;
    }

}