.sidebarClass {
  display: flex;
  justify-content: center;
  /* border-bottom: 1px solid #d6d6d6; */
  cursor: pointer;
  align-items: center;
  gap: 8px;
}
.classImage {
  flex: 0.3;
  padding: 5px 0px;
}
.classimg {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.classPara {
  flex: 0.7;
  text-align: left;
  /* padding: 10px 0; */
  /* margin: auto 0; */
  color: #000;
  font-weight: 600;
}
.classText {
  margin: 10px;
}

.sidebar_stat1 {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #00d498;
  cursor: pointer;
  justify-content: flex-end;
  padding: 0px 15px;
}
