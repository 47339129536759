.refrralImage {
  width: auto;
  height: 200px;
  margin-bottom: 50px;
}
.bottomContainer {
  padding: 20px 65px 40px 65px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0 0 10px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomContainer > a {
  text-decoration: none;
}
.mailBtn {
  padding: 15px 50px;
  border: 1px solid #00d498;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #00d498;
  text-transform: uppercase;
  background-color: white;
}
.mailBtn:hover {
  background-color: #00d498;
  color: #fff;
}
.title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.info {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}
.copyContainer {
  background: #f2f3f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 22px 30px 21px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.link {
  font: normal normal normal 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  margin-right: 20px;
}
