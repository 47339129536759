.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
  margin: 3rem 0rem 7rem 0rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.mainContent {
  display: flex;
  flex: 1;
}

/* Filter Section Styles */
.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.peopleButton {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background-color: #ee2f75;
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.filterDropdown {
  position: relative;
}

.filterButton {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  border: 1px solid #d1d5db;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.dropdownHeader {
  padding: 0.75rem;
  border-bottom: 1px solid #e5e7eb;
  color: #6b7280;
}

.checkbox {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.checkboxItem:last-child {
  border-radius: 0rem 0rem 1rem 1rem;
}

.icon {
  height: 1rem;
  width: 1rem;
}

/* Search Results Styles */
.resultsContainer {
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
}

.resultsHeader {
  display: flex;
  align-items: center;
  padding: 0rem 1rem 1rem 0rem;
}

.iconContainer {
  border-radius: 9999px;
  color: white;
  margin-right: 0.75rem;
}

.headerIcon {
  height: 1.25rem;
  width: 1.25rem;
}

.headerTitle {
  font-size: 1.125rem;
  font-weight: 600;
}

.peopleList {
  flex-direction: column;
  width: 98%;
  box-shadow: rgba(85, 85, 85, 0.1) 0px 0px 20px;
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin: 1rem 0px;
  margin-left: 3px;
}

/* Person Card Styles */
.personCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.personInfo {
  display: flex;
  align-items: center;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  margin-right: 0.75rem;
}

.avatarPlaceholder {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  background-color: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
}

.avatarIcon {
  height: 1.5rem;
  width: 1.5rem;
  color: #9ca3af;
}

.personName {
  font-weight: 500;
  color: #ee2f75;
}

.personTagline {
  font-size: 0.875rem;
  color: #6b7280;
}

.viewProfileButton {
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  border: 1px solid #ee2f75;
  color: #fff;
  background: linear-gradient(to right, #ee2f75, #ff3366, #e44363);
  cursor: pointer;
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
}

.paginationArrow {
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  border: 1px solid #d1d5db;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationIcon {
  height: 1rem;
  width: 1rem;
}

.pageNumber {
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.activePage {
  background-color: #e5e7eb;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

.peopleButton {
  background-color: #ee2f75;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
}

.workButton {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 25px;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
}

.filterDropdown {
  position: relative;
}

.filterButton {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 25px;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Active filter styling */
.activeFilter {
  background: #ee2f75;
  border-color: #ee2f75;
  color: #ffffff;
}

.filterCount {
  background-color: white;
  color: #ee2f75;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 5px;
}

.icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 10;
  width: 16rem;
}

.dropdownHeader {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}

.dropdownContent {
  max-height: 300px;
  overflow-y: auto;
}

.checkboxItem {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.checkboxItem:hover {
  background-color: #f9f9f9;
}

.checkbox {
  margin-right: 10px;
}

.noOptions{
  padding: 1rem;
  text-align: center;
}

/* Selected filters display */
.selectedFiltersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
}

.filterChip {
  background-color: #f1f1f1;
  color: #333;
  border-radius: 25px;
  padding: 5px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
}

.removeChip {
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.removeChip:hover {
  color: #ee2f75;
}

.selected {
  background-color: #fce4ec;
  color: #ee2f75;
  font-weight: bold;
}

.selected:hover {
  background-color: #ebaec4;
  color: white;
}

/* Connections Section Styles */
.connectionsContainer {
  width: 28%;
  display: none;
}

/* No data found */
.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 300px;
}

.noDataImage {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1140px) {
  .connectionsContainer {
    display: block;
    margin-top: 2.5rem;
  }
  .resultsContainer {
    width: 72%;
  }
  .container {
    width: 80vw;
    margin: 0 auto !important;
    margin-top: 7rem !important;
  }
}

@media (min-width: 800px) and (max-width: 1139px) {
  .container {
    margin: 9rem auto;
  }
}
