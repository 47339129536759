.Post {
    /* flex: 0.57; */
    background-color: white;
    margin-bottom: 30px;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #3f3f411a;
    border-radius: 10px;
    position: relative;
  }
  .infoContainer {
    display: flex;
    justify-content: space-between;
  }
  .post_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 7px;
    padding: 30px 26px 0 26px;
  }
  
  .post_Info {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .name_heading_div{
    display: flex;
  }
  
  .post_tag {
    position: absolute;
    right: 70px;
    top: 0;
    padding: 8px 24px 7px 24px;
    background: #ffaf02 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 5px 5px;
    font: normal normal 600 12px/15px Montserrat;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-transform: capitalize;
  }
  
  .post_info_follower {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .post_info_timestamp {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .post_profile_pic {
    height: 65px;
    width: 65px;
    border-radius: 100%;
    object-fit: cover;
  }
  
  .post_Info > p {
    font-size: 12px;
    color: #848484;
  }
  
  .post_header_options {
    color: #b4b4b4;
    order: 2;
    margin-left: auto;
  }
  
  .post_body {
    overflow-wrap: break-word;
    padding: 10px 26px;
  }

  .assignment_post_heading{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }
  
  .post_info_userName {
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .post_option {
    display: flex;
    gap: 20px;
    margin-left: 10px;
    padding-top: 10px;
  }
  .assignment_post_description{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
  }
  .assignment_post_description_para{
    width: 65%;
    color: #707070;
    font-size: 13px;
    overflow-wrap: break-word;
    margin-bottom: 5px;
  }
  
  .assignment_post_container{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 15px 30px;
    justify-content: space-between;
  }

  .assignment_post_attachment_container{
    display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
    margin-top: 20px;
    column-gap: 10px;
  }

  .assignment_post_attachment{
    /* flex: 1; */
    margin-top: 5px;
  }

    
  @media (max-width: 1099px) {
    .Post:last-child {
      margin-bottom: 80px;
    }
  }
  
  
  @media (max-width: 768px) {
    .post_option {
      gap: 0px;
    }
    .assignment_post_attachment_container{
      grid-auto-flow: row;
    }
  }
  @media (max-width: 965px) {
    .post_option {
      gap: 2px;
    }
  }
  @media (max-width: 440px){
    .assignment_post_description_para{
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .assignment_post_open_button{
    margin: auto;
    }
  }
  .translateText{
    font-weight: 500 !important;
    color: #00d498 !important;
    cursor: pointer;
  }