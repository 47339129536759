aside.emoji-picker-react {
  position: absolute !important;
  z-index: 21;
  right: 0;
  top: 41px;
}
/* .fGONoI {
    position: absolute !important;
    z-index: 10;
    left: -100px;
    top: 41px;
} */
