.hidden {
  display: none;
}
.sign-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.fp-bg{
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  opacity: 0.8;
}

.sign-logo-section {
  width: 100vw;
  z-index: 1;
}
.sign-logo {
  margin-top: 20px;
  margin-left: 80px;
}
.sign-container-left {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sign-container-left > img {
  width: 200px;
  height: auto;
}
.sign-heading{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.sign-heading :nth-child(1) {
  text-align: center;
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 700;
}
.sign-heading :nth-child(2) {
  text-align: center;
  font-size: 20px;
  opacity: 1;
  color: black;
  font-weight: 400;
}
.sign-input {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
}
.sign-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column;
}
.sign-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.sign-action > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-alert {
  padding: 5px 0 0 20px;
  font-weight: 600;
  color: #e0003b;
}
.hidden {
  display: none;
}
.sign-field {
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 60px;
  background-color: #f4f4f4;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 15px;
}
.sign-field:focus {
  outline: none;
}
.sign-action > div:first-child {
  display: flex;
}
.checkbox {
  height: 15px;
  width: 15px;
  margin: 0 5px 0 0;
}
.forgot-password {
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-decoration: underline #000000;
}
.sign-btn {
  margin-top: 22px;
  font-size: 16px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2px solid #000000;
  border-radius: 100px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: #000000;
  transition: 0.3s;
  text-transform: uppercase;
}
.button:hover {
  color: white;
  background-color: #333333;
  border: 2px solid #333333;
}

.account-already > span > a {
  color: black;
  text-decoration: none;
  font-weight: 700;
}
.sign-container-right {
  height: 100vh;
  width: 50%;
  object-fit: cover;
  object-position: left;
}
.sign-container-right > div {
  width: 50%;
  color: white;
}
.category-section {
  margin-top: 20px;
}
.category-heading {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}
.category-first {
  display: flex;
  flex-wrap: wrap;
}
.category-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
  flex-grow: 1;
  width: 40%;
}
.category-card:hover {
  background-color: #ee2f75;
  color: white;
  cursor: pointer;
}
.category-card > img {
  height: 80px;
  width: 80px;
  margin-right: 10px;
}
.category-card > div {
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
}
.sign-container-right > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.account-already {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.input-and-error-container {
  display: flex;
  flex-direction: column;
}

.opt-container {
  margin: 10px 0;
}
.verify-btn {
  width: 325px;
  margin-top: 10px;
  background-color: #000000;
  color: white;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}
.resend-otp{
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
}
.forgot-input {
  display: flex;
  flex-direction: column;
}
.fp-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;;
}
.otp-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.verification-heading{
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 700;
}
.otp-image{
  height: 150px;
  width: 200px;
}
.otp-subheading{
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: grey;
}
.otp-subheading2{
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  color: grey;
  text-align: center;
}
/* ############################ Responsive 1650 ############################ */
@media only screen and (max-width: 1650px) {
  .sign-heading :nth-child(1) {
    font-size: 40px;
  }
  .sign-heading :nth-child(2) {
    font-size: 16px;
  }

  .category-card {
    flex-direction: column;
  }
  .category-card > div {
    margin: 0 0;
  }
  .category-card > img {
    margin: 0 0;
  }
}
/* ############################ Responsive 1100 ############################ */
@media only screen and (max-width: 900px) {
  .sign-container-right {
    display: none;
  }
  .sign-container-left {
    width: 100%;
  }
  .sign-container-left > div {
    align-items: center;
  }
  /* .sign-heading {
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
  .category-first {
    align-items: center;
    justify-content: center;
  }
  .category-card > div {
    font-size: 14px;
  }
  .sign-heading :nth-child(1) {
    font-size: 30px;
  }
  .sign-heading :nth-child(2) {
    font-size: 14px;
  }
}
/* ############################ Responsive 600 ############################ */
@media only screen and (max-width: 600px) {
  .category-first {
    flex-direction: column;
    justify-content: center;
  }
  .category-card {
    width: 100%;
  }
  .sign-logo {
    margin-top: 15px;
    margin-left: 10px;
    height: auto;
    width: 150px;
  }
  .sign-btn {
    font-size: 12px;
  }
  .forgot-password {
    font-size: 12px;
  }
  .or {
    margin-top: 12px;
    font-size: 12px;
  }
  .account-already {
    margin-top: 12px;
    font-size: 12px;
  }
}
