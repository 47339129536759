.conversation-time {
    font-size: 0.7rem;
}

.conversation-text {
    font-size: 0.8rem;
}

.fileContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
    align-items: center;

    p {
        margin-bottom: 0;
    }
}