.searchboxWrapper {
    display: flex;
    padding: 0 0.5rem;
    gap: 0.5rem;
    border: 1px solid #eee;
    border-radius: 0.5rem;
}

.searchboxWrapper input {
    width: 100%;
    border: none;
    outline: 0;
    padding: 0.5rem;
}

.searchboxWrapper img {
    width: 1rem;
}
