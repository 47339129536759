.myCoursesFeedContainer {
  overflow-x: hidden;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-evenly;
  gap: 20px;

}

.addCourseCard {
  grid-column: 1 / span 2;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  display: flex;
  width: 100%;
  height: 145px;
  margin-bottom: 20px;
  margin-top: 15px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  position: relative;
}

.innerBorder {
  display: flex;
  border: 1px dashed #b4b4b4;
  border-radius: 10px;
  width: 92%;
  height: 62%;
  cursor: pointer;
  justify-content: center;
}

.addCourseText {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
  align-items: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
}

.plus {
  width: 30px;
}

@media(max-width: 560px) {
  .myCoursesFeedContainer {

    grid-template-columns: auto;


  }
}