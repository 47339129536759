.audioContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 400; */
  background-color: white;
  /* border: 1px solid #000; */
  /* box-shadow: 24; */
  padding: 4;
  width: 400px !important;
  height: 400px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.audioContainer img {
  cursor: pointer;
}

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}

.pulse-ring {
  content: "";
  width: 80px;
  height: 80px;
  background: transparent;
  border: 5px solid #189bff;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  animation: pulsate infinite 1.5s;
}

.audio_buttons_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}

/* .audioContainer button {
  background: transparent;
  cursor: pointer;
  border-radius: 4px;
  height: 30px;
  width: 100px;
  color: white;
  font-weight: 500;
  background: linear-gradient(131.03deg, #d4145a -0.28%, #fbb03b 97.48%);
} */

@media (max-width: 1200px) {
  .audioContainer {
    width: 400px !important;
    height: 400px !important;
  }

  /* .audioContainer button {
    width: 70px;
    height: 20px;
    font-size: 10px;
  } */
}

@media (max-width: 480px) {
  .audioContainer {
    width: 300px !important;
    height: 300px !important;
  }

  .audio_buttons_container {
    flex-direction: column;
    gap: 5px;
  }

  /* .audioContainer button {
    width: 50px;
    height: 15px;
    font-size: 8px;
  } */

  .audioContainer img {
    height: 60px;
    width: 60px;
  }

  .audioContainer p {
    font-size: 10px;
  }
}

@media (max-width: 360px) {
}

@media (max-width: 340px) {
}
