.buisness_page_container {
  display: flex;

  /* flex-direction: row; */
}

.buisness_page_sidebar {
  /* flex: 1; */
  /* background: red; */
  margin-left: 0;
  /* width: 350px; */
}

.parent_buisness {
  flex: 4;
  margin-top: 120px;
  /* margin-left: 300px; */
  margin-bottom: 50px;

  height: 100%;
  /* cursor: pointer; */
}

.buisness_top {
  width: 100%;
}

.buisness_top > h1 {
  font-weight: 700;
  font-style: italic;
}

.buisness_top_chips {
  display: flex;
  font-display: column;
  width: 100%;
  gap: 20px;
}

.buisness_top_chips > span {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  padding: 15px;
  background: #e5e5e5;
  border-radius: 40px;
  font-weight: 700;
  font-size: 15px;
}

.buisness_bottom {
  margin-top: 15px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  /* justify-content: center; */
  row-gap: 50px;
  column-gap: 20px;
}

.event_page_load_more_button {
  margin-top: 20px;
  display: flex;

  justify-content: center;
}

@media (max-width: 1390px) {
  .sale_page_sidebar {
    flex: 2;
  }
}

@media (max-width: 800px) {
  .parent_buisness {
    flex: 4;
    margin-top: 30px;
  }

  .sale_page_sidebar {
    display: none;
    flex: 0;
  }

  .buisness_bottom {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }

  .parent_event {
    margin-top: 50px;
    margin-left: 10px;

    height: 100%;
    margin-bottom: 100px;
  }
}

@media (max-width: 590px) {
  .buisness_bottom {
    grid-template-columns: auto;
    justify-content: space-around;
  }

  .parent_event {
    margin-top: 50px;
    margin-left: 10px;

    height: 100%;
    margin-bottom: 100px;
  }
}

@media (max-width: 400px) {
  .buisness_top_chips > span {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    padding: 15px;
    background: #e5e5e5;
    border-radius: 40px;
    font-weight: 700;
    font-size: 10px;
  }
}
