.locationSearchContainer {
  display: flex;
  align-items: center;
  width: 80%;
  margin-top: 30px;
  gap: 10px;
}

.searchInput {
  padding: 10px;
  border-radius: 8px;
  width: 100%;
}

.getCurrentLocationBtn {
  background-color: transparent;
  border: none;
  color: #0076b6;
  cursor: pointer;
}
