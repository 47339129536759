.individual_comment_div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.individual_comment_div img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
}

.indiviudal_comment_content {
  background-color: #f9f9f9;
  border-radius: 10px;
  flex: 1;
  padding: 10px;
}

.indiviudal_comment_content p {
  font-size: 13px;
  margin-top: 5px;
}

.indiviudal_comment_content_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indiviudal_comment_content_flex p {
  font-size: 13px;
}
