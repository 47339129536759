.outerContainer {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  font-weight: 500;
}

.notification {
  display: flex;
  align-items: center;
  justify-items: center;
}

.notification h1 {
  margin: 0px;
  margin-left: 3px;
}

.dividerLine {
  width: 90%;
  margin: auto;
  height: 0;
  border-top: 1px solid #e5e5e5;
}

.notificationOuterDiv {
  padding-top: 25px;
  padding-bottom: 25px;
}
