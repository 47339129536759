.container {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 5px;
  margin-left: 15px;
  align-items: start;
}

.photo {
  height: 32px;
  margin-right: 10px;
}

.rightInner {
  display: flex;
  gap: 20px;
  order: 2;
  margin-left: auto;
}
.innerBox {
  gap: 25px;
  display: flex;
  flex-wrap: wrap;
}

.photo:hover {
  filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg) brightness(95%) contrast(93%);
}

.post {
  display: flex;
  padding: 13px 30px 2px 30px;
  border: 1px solid #00d498;
  color: #00d498;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
}

.post:hover {
  background-color: #00d498;
  color: #fff;
}

.fileUpload {
  opacity: 0;
  height: 0;
  width: 0;
}
