.cards{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top:24px;
    padding-left:24px;
    padding-right: 24px;
    min-width: 340px;
    height: 130px;
    background: #FFFFFF;
    /* box-shadow: 8px 8px 34px rgba(0, 0, 0, 0.08); */
    border:solid 1px #ebebeb;
    border-radius: 25px;
}

.cards>img{
    width: 60px;
    height:60px;
}

.cards>p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 105%;
}
@media only screen and (max-width: 950px) {
    .cards{
        padding-top:24px;
        padding-left:24px;
        padding-right: 24px;
        min-width: 315px;
        height: 110px;
    }
    
    .cards>img{
        width:50px;
        height:50px;
    }
    
    .cards>p{
        font-size: 22px;
    }
}


@media only screen and (max-width: 650px) {
    
    .cards{
        padding-top:24px;
        padding-left:24px;
        padding-right: 24px;
        width: 100%;
        min-width: 280px;
        height: 110px;
    }
    
    .cards>p{
        font-size: 16px;
        line-height: 105%;
    }
    .cards>img{
        width:40px;
        height:40px;
    }
    
}

@media only screen and (max-width: 480px) {
    .cards{
        min-width: 220px;
        height: 58px;
        border-radius: 22px;
        padding-top:12px;
        padding-left:12px;
        padding-right: 12px;
    }
    
    .cards>img{
        width: 30px;
        height:30px;
    }
    
    .cards>p{
        font-size: 11px;
    }
}