:root {
  /* --primary-main: #ff5722; */
  --primary-main: #f33070;
  --primary-hover: #ff4a10;
  /* --secondary-main: #0074e4; */
  --secondary-main: #f33070;
  --secondary-hover: #0267c8;
  --primary-text: "Lato";
  --primary-optional: #ffb44b;

  --primary-gradient: linear-gradient(to right, #ee2f75, #ff3366, #e44363);
  --secondary-gradient: linear-gradient(to right, #2dc7fe, #3385cc, #2d6dfe);
  --optional-gradient: linear-gradient(to right, #2bb14d, #2b9f4d, #337733);
}
