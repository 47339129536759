.attachment_container{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 2fr 1fr;
    /* justify-content: space-between; */
    align-items: center;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    width: 100%;
    padding: 10px 0;
    /* margin: auto; */
    margin-top: 15px;
    word-break: break-all;
}

.attachment_file{
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.attachment_image{
    margin-right: 10px;
}

.file_type{
    color: #707070;
    font-size: 12px;
    justify-self: center;
}

.file_size{
    color: #707070;
    font-size: 12px;
}

.file_name{
    margin-left: 20px;
    font-size: 14px;
    justify-self: center;
}

.post_attachment_details{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.post_file_name{
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

}

.post_file_desc{
    color: #707070;
    font-size: 12px;
    line-height: 22px;
    
}

.post_file_desc_up{
    text-transform: uppercase;
}

.attachment_post_container{
    width: 100% ;
    overflow: hidden;
    /* display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr; */
    /* justify-content: space-between; */
    align-items: center;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px 0;
    margin: auto;
    word-break: break-all;
}

.file_type{
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .attachment_container{
        width: auto !important;
    }
}

.link_type{
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 5px;

}