.main {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin: 1rem 0;
}

.input_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.input_container > span {
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.input_container > input {
  text-decoration: none;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  margin-left: 0.5rem;
}

.input_container > input::placeholder{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: .8125rem;
  line-height: 16px;
  color: #A6A6A6;
}

.btn_div {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: fit-content;
}

.btn_label {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width:500px){
  .input_container {
    display: block;
  }

  .input_container > input {
    width: 95%;
    margin: 10px 0px;
    font-size: 14px;
  }
  
  .btn_div {
      margin-top: 0.5rem;
      margin-right: 0px;
      grid-gap: 5px;
  }

  .btn_label {
    font-size:8px;
  }

  .input_container > span {
    font-size: 14px;
    font-weight: 600;
  }
}