.headingDiv{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 8px;
}

.headingDiv img{
    margin-right: 10px;
    background-color: #00D498;
    border-radius: 100%;
    padding: 5px;
    height: 20px;
}

.headingDiv h4{
    font-size: 14px;
    text-transform: capitalize;
}