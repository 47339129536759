.dropdown_menu1 {
  width: 470px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  opacity: 1;
  padding: 10px;
  position: relative;
  right: 20px;
}
