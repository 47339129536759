.actionBtn{
    box-sizing: border-box;
    border: 1px solid #00D498;   
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border-radius:50px;
    padding-top:12px;
    padding-bottom:12px;
    width:100%;
    background: #00D498;
    color:white;
    cursor: pointer;
    min-height:none !important;
}

.Outlined{
    background:"white";
    color:"#00D498"
}


@media only screen and (max-width: 800px) {
    .actionBtn{
        font-weight: 700;
        font-size: 14px;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 500px) {
    .actionBtn{
        padding: 10px;
        margin-left: 5px;
    }
}