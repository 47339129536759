.main_container {
    padding-top: 80px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    flex-direction: column;    
    background-image: url("../../animation/Hero/bg1.svg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    max-width:100%
}

.heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 130%;
    text-align: center;
    color: #000000;
    z-index: 1;
    white-space: nowrap;
}

.videos_container {
    width: 80%;
    overflow: hidden;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;    
}

.video_card {
    width: 45%;
}

.video_card > span {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
}



  /* ############################ Responsive 1000 ############################ */
@media only screen and (max-width: 1000px) {
    .heading {
      font-size: 2rem;
      white-space: nowrap;
      text-align: center;
    }
    .main_container {
        padding-top: 120px;
    }

    .videos_container {
        display: flex;
        flex-direction: column;
        width: 80%;
        justify-content: center;
        align-items: center;
    }
    .video_card {
        width: 100%;
        margin-bottom: 50px;
    }

    .video_card > span {
        font-size: 1.3rem;
        white-space: nowrap;  
        margin-top: 10px;              
    }

  }

/* ############################ Responsive 600 ############################ */
@media only screen and (max-width: 600px) {
    .heading {
        font-size: 1.7rem;
    }
    
    .videos_container {
        width: 80%;
        margin-bottom: 50px;
    }
    
    .video_card {
        width: 100%;
        margin-bottom: 25px;
    }

}

/* ############################ Responsive 400 ############################ */

.main_container {
    padding-left: 10px;
    padding-right: 10px;
}