.action_modal_container{
  background: #ffffff 0% 0% no-repeat padding-box;
  height: fit-content;
  padding-top: 5px;
  padding: 30px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 63vh;
}

.header_text{
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.main_text{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}

.buttons{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@media(max-width: 600px){
  .action_modal_container {
    width: 75%
  }
}

@media(max-width: 400px){
  .action_modal_container {
    width: 70%
  }
}