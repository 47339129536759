.CoursesContainer {
    margin: 100px 0px 100px 50px;
    overflow-x: hidden;
}


.loader_style{
    text-align: center;
     margin-top: 200px
  }

.CourseHeadingContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0px 20px 10px;
}

.CourseHeading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    margin-left: 15px;
}

.CourseCardsVerticalScroll {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
    .CoursesContainer {
        margin: 0px 0px 100px 50px;
    }
}

@media screen and (max-width: 500px) {
    .CoursesContainer {
        margin: 0px 0px 100px 0px;
    }
}