.main {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin: 1rem 0;
}

.heading {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  color: #000;
  margin-bottom: 0.75rem;
}

.input_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.input_container > span {
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.input_container > input {
  text-decoration: none;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  margin-left: 0.5rem;
}

.input_container > input::placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 16px;
  color: #a6a6a6;
}

.content_container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.content_container > span {
  font-size: 0.875rem;
  font-weight: 400;
}

.content {
  display: flex;
  width: 100%;
  justify-self: flex-end;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.5rem;
  margin-left: 0.5rem;
}

.content > span {
  color: #a6a6a6 ;
  font-size: .875rem;
  font-family: "Montserrat";
  font-weight: 400;
  margin-right: .625rem;
}

.content > img {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  /* margin-left: auto; */
}

.btn_div {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: fit-content;
}

.btn_label {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width:500px){
  .heading {
    font-size: 13px;
    font-weight: 600;
  }
  .input_container > span {
    font-size: 13px;
  }
  .input_container > input {
    font-size: 13px;
  }

  .content > span {
    font-size: 13px;
  }

  .content_container > span{
    font-size: 13px;
  } 
  .btn_label {
    font-size: 8px;
  }
}
