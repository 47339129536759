.map-container {
  display: flex;
  position: relative;
  z-index: 0;
}

.map {
  flex: 1;
  height: 93vh;
}

.card {
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: absolute;
  margin-top: 10%;
  left: 20px;
  width: 320px;
  max-height: 60vh;
  z-index: 99999;
  border-radius: 25px;
  overflow: auto;
}

.places-list {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.place-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.place-item:hover {
  background-color: #f5f5f5;
}

.place-item.selected {
  background-color: #e0e0e0;
}

.place-details {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

@media (max-width: 800px) {
  .card {
    max-height: 55vh;
  }
}
