.placeholderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    margin-top: 3rem;
}

.placeholderWrapper p {
    text-align: center;
    font-size: 1rem;
}
