.modalBody {
  background-color: #fff;
  /* padding: 0px 20px 12px 20px; */
  border-radius: 0 0 8px 8px;
}

.textareaDisc {
  border-radius: 10px;
  width: 95%;
  padding: 10px;
  border: 1px solid #55555533;
}

.crossSvg {
  margin-top: -2px;
  margin-left: -2px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(271deg)
    brightness(108%) contrast(102%);
}
