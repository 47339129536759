.socialhandle {
  display: flex;
}

.container {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.socialContainer {
  display: flex;
  justify-content: space-between;
}

.socialContainer > img {
  cursor: pointer;
}

.copyContainer {
  background: #f2f3f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 22px 30px 21px 30px;
  display: flex;
  align-items: center;
}

.link {
  font: normal normal normal 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.copyButton {
  order: 2;
  margin-left: auto;
  cursor: pointer;
}
