.due_date_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.due_date {
  margin-left: 10px;
  /* font-weight: 600; */
  font-size: 11px;
}
