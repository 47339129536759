.main {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 33px;
  margin-top: 1rem;
}

.header {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
}

.header > img {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
}

.url_container,
.file_container {
  display: flex;
  border: 1px solid #e5e5e5;
  border-radius: 33px;
  padding-left: 0.75rem;
}

.url_input,
.file_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border-right: 1px solid #e5e5e5;
  padding: 5px;
  color: #000;
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 300;
}

.placeholder_text {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
  color: #a6a6a6;
}

.url_input > input,
.file_input > input {
  outline: none;
  width: 100%;
}

.url_input > img,
.file_input > img {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  margin-left: auto;
}

.btn_add_url,
.btn_add_article {
  display: flex;
  width: fit-content;
  min-width: 20%;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
}

.btn_add_article > button,
.btn_add_url > button {
  width: 100%;
  height: 100%;
  background-color: white;
  text-decoration: none;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
  /* white-space: nowrap; */
  color: #000;
  font-size: 0.875rem;
  font-family: "Montserrat";
  font-weight: 500;
}

.btn_add_article > button:active,
.btn_add_url > button:active {
  transform: scale(0.98);
  box-shadow: 2px 1px 1px 1px rgba(0, 0, 0, 0.14);
}

/* .btn_add_article span {
  white-space: nowrap;
} */

.url_input > ::placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
  color: #a6a6a6;
}

.time_input_container > input {
  width: 1.1rem;
  text-align: center;
  margin-right: 5px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.875rem;
  /* line-height: 16px; */
  color: #000;
  outline: none;
}

.time_input_container > input::placeholder {
  font-size: 0.875rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  /* line-height: 24px; */
  color: #a6a6a6;
}

.time_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.time_container {
  display: flex;
  border: 1px solid #e5e5e5;
  border-radius: 33px;
  padding-left: 0.75rem;
  margin-top: 1rem;
}

.time_unit {
  color: #000;
  font-size: 0.875rem;
  font-family: Montserrat;
}

.colon {
  color: #000;
  font-size: 0.875rem;
  font-family: Euclid Square;
  font-style: normal;
  font-weight: 600;
  padding: 0 5px;
}

@media (max-width:500px){
  .main {
    margin-top: 0rem;
  }

  .header {
    padding: 10px 20px;
  }
  
  .header > span{
    font-size: 14px;
    font-weight: 500;
  }

  .btn_add_article button{
    font-size: 10px;
    font-weight: 500;
  }

  .placeholder_text {
    font-size: 10px;
  }

  .time_unit {
    font-size: 10px;
    margin-top: 5px;
  }

  .url_input > input,.file_input > input {
  font-size: 10px;
  height: 20px;
  }

  .btn_add_url > button{
    font-size: 10px;
  }

  .url_input,.file_input {
  font-size: 10px;
  }

  .url_container,
.file_container {
    height: 33px;
}

}