.mobileBurger {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: white;
  transform: translateX(150%);
  transition: all ease-in 1s;
  overflow-x: hidden;
}

#mobileBurg {
  display: none;
}

.mobileBurger.showBurger {
  transform: translateX(0) !important;
}

@media (min-width: 800px) {
  .mobileBurger {
    left: 350px;
  }
}
