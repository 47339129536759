.Overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: #232D4C; */
  background: #000;
  opacity: 0.5;
}

.Modal {
  z-index: 110;
  /* position: fixed; */
  position: absolute;
  top: 50%;
  background: white;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ModalContainer {
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}
.classModal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.formFields {
  padding: 0 30px;
  box-sizing: border-box;
}
.classModal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.formFields {
  padding: 0 30px;
  box-sizing: border-box;
}
.inputLabel {
  font-size: 16px;
  margin: 10px 0 10px 20px;
  font-weight: normal;
}
.inputField {
  border-radius: 25px;
  width: 550px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}
/* .NewScrollBar::-webkit-scrollbar {
    width: 7px;
    height: 7px;
   }
.NewScrollBar::-webkit-scrollbar-thumb {
background: linear-gradient(13deg, #f9d4ff 14%,#c7ceff 64%);
border-radius: 10px;
}
.NewScrollBar::-webkit-scrollbar-thumb:hover{
background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%);
}
.NewScrollBar::-webkit-scrollbar-track{
background: #ffffff;
border-radius: 15px;
box-shadow: inset 17px 0px 0px #f0f0f0;
} */
