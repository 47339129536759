.container {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  padding: 30px 30px 20px 30px;
  flex-direction: column;
  height: auto;
}
.qrModalContainer {
  /* max-width: 540px; */
}
.qrImage {
  display: flex;
  justify-content: center;
}

.qrImage > img {
  width: 85px;
  height: 85px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
}

.qrdetails {
  padding-bottom: 30px;
  padding-top: 10px;
}

.qrdetails > h3 {
  text-align: center;
  font: normal normal 600 24px/29px var(--primary-text);
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.qrdetails > h4 {
  text-align: center;
  font: normal normal normal 18px/22px var(--primary-text);
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  padding-top: 10px;
}

.qrcode > img {
  width: 170px;
  height: 170px;
}

.qrcode {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrbuttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.qrButton:nth-child(1) {
  margin-right: 20px;
}

.qrButton {
  border: 1px #f13073 solid;
  background-color: white;
  border-radius: 2rem;
  width: 200px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #f13073;
  cursor: pointer;
  text-transform: capitalize;
}

.qrButton:hover {
  background-color: #f13073;
  color: white;
}
@media only screen and (max-width: 600px) {
  .qrbuttons {
    flex-direction: column;
  }
  .qrButton:nth-child(1) {
    margin-bottom: 20px;
  }
  .qrModalContainer {
    min-width: 600px;
  }
}
