.myCoursesContainer {
    margin: 0px 0px 150px 100px;
    overflow-x: hidden;
}

.myCoursesTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myCoursesTopHeading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 15px;
    border-left: 4.5px solid #00d498;
    margin-top: 30px;
    margin-bottom: 30px;
    width: fit-content;
}

.myCoursesTop img {
    margin-right: 15px;
    display: none;
}

.myCoursesMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hideMyCoursesMobile {
    display: none;
}

.mobileEnCourses{
    display: none;
}

.myCoursesMobileHeading {
    display: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 15px;
    margin-bottom: 30px;
    border-left: 4.5px solid #00d498;
    width: fit-content;
}

.myCoursesMobile img {
    margin-right: 15px;
    margin-bottom: 30px;
    display: none;
}

.myCoursesSecondHeading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 15px;
    border-left: 4.5px solid #00d498;
    margin-top: 30px;
    margin-bottom: 30px;
    width: fit-content;
}

.myCoursesPlusSign {
    background: #00d498 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    width: 38px;
    height: 38px;
    margin-bottom: 20px;
    color: white;
}

.CreateCardText {
    text-align: center;
    line-height: 24px;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: bold;
    letter-spacing: 0px;
    color: #555555;
    text-transform: capitalize;
    opacity: 1;
}

.CreateCardContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px dashed #b4b4b4;
    display: flex;
    min-width: 257px;
    height: 365px;
    margin-bottom: 20px;
    margin-right: 30px;
    border-radius: 10px;
    opacity: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CreateCardContainer:hover {
    cursor: pointer;
}

.CourseCardsVerticalScroll {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.CourseCardsVerticalScrollMobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 50px;
}

.CreatedCardsBox {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.CourseCardsScrollMobile{
    display: none;
}

.CourseCardsHorizontalScroll {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: scroll !important;
    margin-right: 30px;
}

.CourseCardsScrollMobileStudent{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: scroll !important;
    margin-right: 30px;
    padding-top: 50px;
}

.CourseCardsHorizontalScroll::-webkit-scrollbar {
    display: none;
}

.DisplayEnCourseDisplay{
    display: block;
}

.hideEnCourse{
    display: none;
}

@media screen and (max-width: 800px) {
    .myCoursesContainer {
        margin: 0px 0px 150px 50px;
    }
    .CourseCardsScrollMobileStudent{
        padding-top: 20px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 500px) {

    .myCoursesContainer {
        margin: 0px 0px 150px 10px;
    }

    .myCoursesTop img {
        display: flex;
    }

    .myCoursesMobile img {
        display: flex;
    }

    .myCoursesMobileHeading {
        display: flex;
    }

    .mobileEnCourses{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .CreateCardContainer {
        display: none;
    }

    .CreatedCardsBox {
        display: none;
    }

    .CourseCardsScrollMobile {
        display: flex; 
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .CourseCardsScrollMobileStudent{
        padding-top: 20px;
        display: flex; 
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 0px;
    }

    .myCoursesSecondHeading {
        display: none;
    }

    .CourseCardsVerticalScroll {
        display: none;
    }

    .CourseCardsVerticalScrollMobile {
        display: none;
    }
}