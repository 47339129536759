.main {
    display: flex;
    margin: auto;
    width: 90vw;
    max-width: 1200px;
    margin-top: 120px;
    box-shadow: 0 0 10px #d1d1d1;
    height: calc(100vh - 120px - 30px);
}

.message-list {
    width: 30%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    border-right: var(--bs-border-width) var(--bs-border-style)
        var(--bs-border-color);
}

.conversations {
    flex-grow: 1;
    position: relative;
}

.messageWindowPlaceholder {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--bs-body-color);
    font-weight: bold;
    position: relative;
}


@media (max-width: 1140px) {
    .main {
        height: calc(100vh - 9rem - 70px);
    }

    .message-list {
        width: 35%;
    }

    .conversations {
        width: 65%;
    }

    .messageWindowPlaceholder {
        width: 65%;
    }
}

@media (max-width: 800px) {
    .main {
        margin-top: 20px;
        margin-bottom: 8rem;
        height: calc(100vh - 8rem - 70px);
    }

    .message-list {
        border-right: 0px;
        width: 100%;
        max-width: 800px;
    }

    .conversations {
        width: 100%;
    }

    .messageWindowPlaceholder {
        display: none !important;
    }
}

.messageWindowPlaceholder {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--bs-body-color);
    font-weight: bold;
}

.profilePic {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    object-fit: cover;
}

.message-list-container input {
    outline: none;
    font-size: 0.8rem;
}

.message-list-container {
    flex-grow: 1;
    border-top: var(--bs-border-width) var(--bs-border-style)
        var(--bs-border-color);
}

.searchbox-wrapper {
    display: flex;
    padding: 0 0.5rem;
    gap: 0.5rem;
    align-items: center;
}

.searchbox-wrapper input {
    width: 100%;
}

.searchbox-wrapper img {
    width: 1rem;
}
.conversation-footer {
    position: fixed;
    bottom: 3rem;
}

.conversation-image {
    height: 2.5rem;
    width: 2.5rem;
    background-position: center;
    background-size: cover;
}

.conversation-body {
    height: calc(100% - 9rem);
}

.conversations {
    display: block;
}


.dateHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    margin: 1rem 0px;
    p {
        text-align: center;
        text-transform: uppercase;
        color: gray;
        font-weight: 600;
        background-color: white;
        padding: 0 1rem;
        margin-bottom: 0px;
        z-index: 20;
    }
}

.dateHeader .line {
    position: absolute;
    top: 50%;
    width: 100%;
    background-color: lightgray;
    height: 1px;
    z-index: 10;
    transform: translateY(-50%);
}

.addContainer {
    cursor: pointer;
}

.addContainer:hover{
    background: rgba(238, 238, 238, 0.3);
    border-radius: 50%;
}

.fileInput {
    display: none;
}

.fileContainer{
    padding: 1rem;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: bold;
    font-size: .9rem;
    border-top: var(--bs-border-width) var(--bs-border-style)
        var(--bs-border-color);
    border-bottom: var(--bs-border-width) var(--bs-border-style)
        var(--bs-border-color);
}