.input_link{
    border: 1px solid #5555555A;
    padding: 10px;
    font-size: 15px;
    border-radius: 50px;
    width: 90%;
}

.button_div{
    margin: 15px;
    width: 90%;
    display: flex;
    justify-content: flex-end;
}