* {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: var(--primary-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* hide scrollbar on Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar on Firefox */
html {
    scrollbar-width: none;
}

textarea,
input[type="text"],
.textinp {
    font-family: var(primary-text);
}

.textarea:focus,
textarea:focus,
#input_profile {
    outline: none !important;
    /* box-shadow: 0 0 10px #00D498; */
}

.input_foc:focus {
    box-shadow: 0 0 10px #00d498;
    outline: none !important;
}

.btn,
.btn_fade {
    background-color: #fff;
}

.center_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner_invitation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    margin: 10px 0px;
}

.border_color {
    border-color: var(--secondary-main);
}

.flex_sp {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.invitationSection {
    display: flex;
}

.connect_btn {
    background-color: #f33070;
    border: 1px solid #f33070;
    padding: 9px 20px;
    border-radius: 16px;
    cursor: pointer;
    /* margin-top: 10px; */
    color: #fff;
    text-transform: capitalize;
}

.connect_btn_fade {
    border: 1px solid #c71f3c;
    padding: 5px 20px;
    border-radius: 16px;
    cursor: pointer;
    margin-right: 10px;
    color: "#fff";
    text-transform: capitalize;
    background-color: #c71f3c;
}

.connect_btn_fade_remove {
    border: 1px solid #c71f3c;
    padding: 9px 20px;
    border-radius: 16px;
    cursor: pointer;
    margin-right: 10px;
    color: "#fff";
    text-transform: capitalize;
    background-color: #c71f3c;
}

.flex_col_cen {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inner_invitation_img {
    margin-right: 10px;
}

.search_global_mains {
    display: flex;
    justify-content: center;
}

.inner_mains {
    width: 80em;
    background-color: green;
}

.invitation_content {
    border-radius: 10px;
    box-shadow: rgba(85, 85, 85, 0.1) 0px 0px 15px;
}

.suggestion_card_div {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.suggestion_card {
    height: 250px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin: 5px;
    box-shadow: rgba(85, 85, 85, 0.1) 0px 0px 15px;
}

.suggestion_card_header {
    height: 30%;
    flex: 2;
    background-color: #00d498;
    display: flex;
    justify-content: flex-end;
}

.suggestion_card_body {
    flex: 3;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    padding: 40px 15px 0px 15px;
}

.suggestion_card_avatar_div {
    position: absolute;
    border-radius: 50%;
    top: 50px;
    left: 10px;
}

.suggestion_card_avatar_div img {
    height: 60px;
}

.dismiss_suggestion_card {
    color: #113474;
    cursor: pointer;
    margin: 10px;
    height: 22px;
}

.desc {
    margin: 0px;
}

.btn_text {
    margin: 0;
    letter-spacing: 0px;
    color: #fff;
    text-transform: capitalize;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;
}

.btn_text_fade {
    margin: 0;
    font-size: 14px;
    letter-spacing: 0px;
    color: #fff;
    text-transform: capitalize;
    opacity: 1;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
}

.btn:hover {
    background-color: #fff;
}

.btn_fade:hover {
    background-color: #fff;
}

.btn:hover .btn_text {
    color: #f33070;
}

.btn_fade:hover .btn_text_fade {
    color: #c71f3c;
}

.up:hover {
    background-color: #00d498;
}

.up:hover .up_desc {
    color: #fff;
    text-decoration: underline;
}

.up_desc {
    color: #828282;
}

.pilus {
    color: #f33070;
}

.hov:hover,
.hov:hover .pilus {
    background-color: #f33070;
    color: #fff;
}

/* ADS COROUSEL */

.slideshow {
    overflow: hidden;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 100ms;
}

.slide {
    display: inline-flex;
    width: 100%;
    border-radius: 10px;
}

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0px 7px 0px;
    background-color: #fff;
}

.slideshowDot_active {
    width: 20px;
}

#scroll_div::-webkit-scrollbar {
    display: none;
}

.scroll_div::-webkit-scrollbar {
    display: none;
}

#scroll_div,
.scroll_div,
select {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/*language */

.box {
    width: 89px;
    height: 29px;
    background: gray;
    border-radius: 16px;
    opacity: 1;
    box-sizing: border-box;
}

/* FEATURED DROPDOWN */

.dropdown_featured {
    position: relative;
    display: inline_block;
}

.dropdown_content_featured {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: -30px;
}

.dropdown_content_featured a {
    color: rgb(116, 116, 116);
    padding: 10px 10px;
    text-decoration: none;
    display: block;
    font-size: 13px;
    font-weight: 600;
}

.dropdown_content_featured a:hover {
    background-color: #f1f1f1;
}

.dropdown_featured:hover .dropdown_content_featured {
    display: block;
}

/* HEADER CSS */

.header {
    position: fixed;
    display: flex;
    background-color: white;
    justify-content: space-evenly;
    z-index: 20;
    top: 0px;
    left: 0px;
    right: 0px;
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    box-shadow: 0px 0px 20px #3f3f411a;
    opacity: 1;
    /* padding-left: 40px; */
    padding-top: 17px;
    padding-bottom: 17px;
    width: 100%;
    align-items: center;
}

.header_left {
    display: flex;
    margin-left: 70px;
    width: 100%;
    gap: 30px;
    align-items: center;
}

.header_left > img {
    object-fit: contain;
    top: 21px;
    left: 270px;
    width: 178px;
    height: 40px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 5px;
}

.header_search {
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    width: 250px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    opacity: 1;
    margin-left: 25px;
    margin-top: 5px;
}

.header_search > form > input {
    font: italic normal medium 13px/16px var(--primary-text);
    outline: none;
    border: none;
    background: none;
    width: 160px;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
    margin-right: 10px;
}

.header_right {
    top: 20px;
    height: 50px;
    margin-left: 50px;
    margin-right: 70px;
    gap: 30px;
    display: flex;
    align-items: center;
    width: 40%;
}

.header_option {
    text-align: center;
    display: grid;
    place-items: center;
    flex-grow: 1;
    flex-direction: column;
}

.header_option a{
    text-decoration: none;
}

.header_option_text{
    font-size: 11.5px;
    font-weight: 500;
    color: rgb(102, 102, 102);
    text-decoration: none;
}

.image {
    width: 24px;
    height: 18px;
    background: transparent 0% 0% no-repeat padding-box;
    cursor: pointer;
}

.header_avatar {
    width: 45px !important;
    height: 45px !important;
    border-radius: 100%;
    object-fit: cover;
    opacity: 1;
    background: transparent 0% 0% no-repeat padding-box;
    cursor: pointer;
    opacity: 1 !important;
}

.specific {
    text-align: center;
    font: normal normal normal 13px/16px var(--primary-text);
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
    height: 16px;
    cursor: pointer;
}

.Search {
    color: #b4b4b4;
}

.profile_icon {
    margin-left: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    /* gap: 15px; */
}

.header_options_container {
    display: grid;
    place-items: center;
}

.profile_icon img {
    width: 25px;
    height: 25px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 0.3;
}

.header_search img {
    height: 18px;
    width: 19px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 0.5;
    margin-right: 15px;
}

.notifications_menu {
    position: absolute;
    width: 230px;
    height: 12em;
    box-shadow: 0px 0px 20px #55555529;
    border-radius: 10px;
    opacity: 1;
    top: 96px;
    background-color: #fff;
    /* margin-left: -130px; */
}

.notifications_menu ul {
    list-style: none;
    padding: 0px 0px;
}

.notifications_menu li {
    padding: 0px 15px;
}

.notification {
    display: flex;
    align-items: center;
    justify-items: center;
}

.notification h1 {
    margin: 0px;
    margin-left: 3px;
}

.dropdown_menu {
    width: 250px;
    height: auto;
    box-shadow: 3px 2px 4px 2px #55555529;
    border-radius: 5px;
    opacity: 1;
    margin-top: 350px;
    margin-left: -250px;
    background-color: #fff;
    z-index: 10;
}

.dropdown_menu ul {
    list-style: none;
    padding: 10px 0px;
    margin: 0;
}

.dropdown_menu li {
    padding: 5px 15px;
}

.profile {
    display: flex;
}

.profile h1 {
    margin: 0px;
    margin-left: 10px;
}

.drop_profile {
    border: 2px solid var(--primary-main);
    border-radius: 50%;
}

.Hr2 {
    height: 0px;
    border: none;
    border-bottom: 1px solid #e5e5e5;
}

.menu {
    text-align: left;
    font-size: 12px;
}

.navbar_menu {
    /* width: 80px; */
}

.icon_container {
    background-color: #00d498;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.inner_icon {
    height: 30px;
    width: 30px;
}

.icon_text {
    text-transform: capitalize;
    margin: 0px;
    font-weight: 700;
    font-size: 16px;
    margin-left: 15px;
    letter-spacing: 0px;
}

.icon_text_large {
    text-transform: capitalize;
    margin: 0px;
    font-weight: 500;
    font-size: 32px;
    margin-left: 15px;
    letter-spacing: 0px;
}

.featured_desc {
    font: normal normal normal 14px/18px var(--primary-text);
    color: #000000;
    opacity: 0.7;
    letter-spacing: 0px;
    /* height:3.3rem; */
    width: 15.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.section_desc {
    margin: 0px;
    font-size: 14px;
    line-height: 21px;
    color: #606060;
    letter-spacing: 0px;
    word-break: break-all;
    white-space: pre-wrap;
}

.ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.edit_info {
    font: normal normal normal 16px/19px var(--primary-text);
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
    margin: 0px 0px 15px 0px;
}

.edit_info {
    margin: 10px !important;
}

.mains_search {
    width: 70em;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 40px;
}

.see_all_text {
    font: normal normal 600 12px/15px var(--primary-text);
    letter-spacing: 0px;
    color: #555555;
    text-transform: uppercase;
    opacity: 1;
    margin: 0px;
    padding: 10px;
}

.followers {
    font: normal normal normal 14px var(--primary-text);
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
    margin-top: 5px;
}

/* MODALS */

.modal_title {
    font: normal normal 600 17px/20px var(--primary-text);
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    margin-left: 10px;
}

.modal_heading {
    font: normal normal 500 16px/19px var(--primary-text);
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 8px;
}

.modal_date {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.modal_date > div {
    display: flex;
    flex-direction: column;
}

.modal_date > div:nth-child(1) {
    margin-right: 20px;
}

.sub_heading {
    font-size: 16px;
    margin: 0;
}

.imageUpload {
    margin-bottom: 20px;
}

.modal_para {
    outline: none !important;
    font: normal normal normal 14px/24px var(--primary-text);
    letter-spacing: 0px;
    color: #555555;
    border: 1px solid #5555555a;
    padding: 15px;
    border-radius: 25px;
    margin: 0px;
}

.mar0 p {
    margin: 0px;
}

/* MAINS */
.noResult {
    height: auto;
    width: 30vw;
}

.feed {
    margin-top: 100px;
    padding: 0px 10px;
}

.profile_margin {
    margin: 0 8em;
}

.header_nav {
    display: none;
}

.header_option {
    display: flex;
}

.jobs_icon{
    filter: invert(49%) sepia(89%) saturate(361%) hue-rotate(102deg) brightness(90%) contrast(85%) !important;
} 
.course_icon{
    filter: invert(74%) sepia(72%) saturate(398%) hue-rotate(7deg) brightness(102%) contrast(95%);
}

.cover_feed {
    width: 80%;
}

.left_feed {
    width: 70%;
}

.right_feed {
    width: 30%;
}

.left_side_bar {
    margin: 0px 10px 0px 0px;
    width: 20%;
    display: flex;
    justify-content: center;
}

.header_left img {
    display: block;
}

#template_modal {
    width: 560px;
}

textarea,
.textarea {
    width: 100%;
    resize: none;
    scrollbar-width: none;
}

#hamburger_card {
    width: 500px;
    padding: 30px 20px 30px 20px;
    box-sizing: border-box;
}

.hamburger_view {
    display: none;
}

.leftbar_hamburger_view {
    display: none;
    margin-top: -120px;
}

.wrap_100 {
    width: 50%;
}

.search_feed {
    width: 90%;
}

.peopleSection {
    width: 100%;
}

.coverPicture {
    width: 100%;
    border-radius: 10px;
    height: 100%;
}

.editdescfeed_dropdown {
    position: relative;
    display: inline-block;
}

.editdescfeed_dropdown_content {
    display: none;
    position: absolute;
    top: 50px;
    left: -95px;
    background-color: #ffffff;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.editdescfeed_dropdown_content a {
    color: #555555;
    padding: 8px 8px;
    text-decoration: none;
    display: block;
    border: 2px solid rgb(247, 247, 247);
    font-size: 12px;
}

.editdescfeed_dropdown_content a:hover {
    background-color: #ddd;
}

.editdescfeed_dropdown:hover .editdescfeed_dropdown_content {
    display: block;
}

.root_search {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.icon_container {
    background-color: var(--primary-main);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.inner_icon {
    height: 30px;
    width: 30px;
}

.icon_text {
    margin: 0px;
    font-weight: 700;
    font-size: 16px;
    margin-left: 15px;
    letter-spacing: 0px;
}

.suggestion_card_div {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    /* grid-template-columns: repeat(auto-fill, 17.5em); */
}

.suggestion_card {
    height: 250px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin: 5px;
    box-shadow: rgba(85, 85, 85, 0.1) 0px 0px 15px;
}

.suggestion_card_avatar_div {
    position: absolute;
    border-radius: 50%;
    top: 50px;
    left: 10px;
}

.suggestion_card_avatar_div img {
    height: 60px;
}

.suggestion_card_header {
    height: 30%;
    flex: 2;
    background-color: #00d498;
    display: flex;
    justify-content: flex-end;
}

.dismiss_suggestion_card {
    color: #113474;
    cursor: pointer;
    margin: 10px;
    height: 22px;
}

.suggestion_card_body {
    flex: 3;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    padding: 40px 15px 0px 15px;
}

.inner_invitation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 10px 0px;
}

.flex_col_cen {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section_title {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.center_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner_invitation_img {
    margin-right: 10px;
}

.search_global_mains {
    display: flex;
    justify-content: center;
}

.tagList {
    background-color: #efefef;
    display: inline-block;
    border-radius: 20px;
    padding: 5px 15px 5px 15px;
    font-weight: 600;
    margin: 5px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 14px;
}

.langList {
    display: flex;
    padding: 5px 15px 5px 15px;
    font-weight: 600;
    margin: 5px;
    align-items: center;
    position: relative;
}

.langHr {
    border: 2.4px solid;
    width: 16px;
    margin: 0 10px;
    border-radius: 10px;
}

.buttonsContainer .button {
    display: flex;
    padding: 16px 30px 16px 30px;
    border: 1px solid var(--secondary-main);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.2s;
    font: normal normal 600 12px/15px var(--primary-text);
    letter-spacing: 0px;
    color: var(--secondary-main);
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 23px;
}

.buttonsContainer .button:hover {
    background-color: var(--secondary-hover);
    color: #fff;
}

/* Modal popups */
.modal_wrapper {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
}

.modal_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #5555555a;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.scroll_div_class {
    max-height: 400px;
    max-width: 600px;
    overflow: scroll;
    padding: 3px;
}

.save_btn_wrapper {
    margin-top: 40px;
}

.save_btn {
    border: 2px solid var(--secondary-main);
    padding: 12px 60px;
    border-radius: 40px;
    cursor: pointer;
}

.save_btn_p {
    margin: 0px;
    font-weight: 600;
    text-transform: uppercase;
}

.pointer_div {
    cursor: pointer;
}

.modal_input_text {
    border: 1px solid #5555555a;
    padding: 10px;
    font-size: 15px;
    border-radius: 50px;
}

.sidebar_stats {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #ffffff;
    border-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 20px #3f3f411a;
}

.Hr {
    height: 0px;
    border: none;
    border-bottom: 1px solid #e5e5e5;
}

.sidebar_stat1 {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 5px;
}

.sidebar_stat1 > p {
    display: flex;
    color: var(--secondary-main);
    font: normal normal 600 13px/16px var(--primary-text);
}

.language_header {
    text-decoration: none;
    background-color: transparent;
    width: 40px;
    cursor: pointer;
}

.langTextDiv {
    align-items: center;
    margin: auto;
    font: normal normal normal 13px/16px var(--primary-text);
    /* height: 16px */
}

.language_div {
    display: flex;
    cursor: pointer;
    position: relative;
}

.language_div_dark {
    display: flex;
    color: #000;
    cursor: pointer;
    position: relative;

    text-align: center;
    display: grid;
    place-items: center;
}

.dropdownLanguage {
    width: 100px;
    height: 80px;
    box-shadow: 3px 2px 4px 2px #55555529;
    border-radius: 5px;
    position: absolute;
    top: 50px;
    left: -10px;
    opacity: 1;
    background-color: #fff;
    z-index: 10;
}

.dropdownList {
    list-style: none;
    padding: 0;
    height: 93.6px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.inactiveList {
    margin-top: 6px !important;
    color: #555555;
    cursor: "pointer";
    font-size: 15.5px;
}

.activeList {
    margin-top: 6px !important;
    color: var(--primary-main);
    cursor: "pointer";
    font-size: 15.5px;
}

.icon_container_assignment {
    background-color: #00d498;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: 38px;
    width: 38px;
}

.inner_icon_assignment {
    height: 24px;
    width: 24px;
}

/* New Search bar */
.search_bar_box {
    height: 35px;
    width: 80%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 4px 15px;
}

.search_bar_start {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-text);
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    cursor: pointer;
}

.deals {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    padding: 5px;
    width: 91%;
    top: 40px;
    box-shadow: 3px 2px 4px 2px #55555529;
    text-align: center;
}

.down_arrow_icon {
    cursor: pointer;
    height: 25px;
    margin-left: 12px;
}

.down_search_icon {
    cursor: pointer;
    height: 22px;
    margin-left: 25px;
}

.down_deals_icon {
    cursor: pointer;
    height: 22px;
}

.search_icon {
    cursor: pointer;
    background: linear-gradient(131.03deg, #d4145a -0.28%, #fbb03b 97.48%);
    border-radius: 4px;
    height: 25px;
}

.mic_icon {
    cursor: pointer;
    height: 22px;
}

.search_bar_mid {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
}

.searchbar_input {
    width: 94%;
    border: none;
    outline: none;
    background-color: #f5f5f5;
    height: 88%;
    margin-left: 16px;
    font-family: var(--primary-text);
}

.search_bar_end {
    width: 25%;
    height: 100%;
    /* background-color: gray; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1%;
    align-self: flex-end;
    gap: 8px;
}

/* location search */
.location_search {
    display: flex;
    height: 35px;
    border-radius: 10px;
    width: 20%;
    align-items: center;
    justify-content: center;
    gap: 7px;
    cursor: pointer;
    padding: 4px 10px;
    /* overflow: hidden; */
    background-color: #f5f5f5;
}

.location_search_text {
    font-size: 15px;
    font-family: var(--primary-text);
    margin: 0;
}

.location_hover {
    width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    position: absolute;
    /* left: 10%; */
    top: 50px;
    padding: 4px 10px;
    font-size: 15px;
    font-family: var(--primary-text);
    display: none;
}

/* .location_search:hover {
  background-color: yellow;
  display: flex;
} */

/* MEDIA */
@media (max-width: 1350px) {
    .left_feed {
        width: 70%;
    }

    .featured_scroll {
        width: 600px;
    }

    .feed {
        /* width: 94em; */
        font-size: small;
    }

    .right_feed {
        display: block;
    }

    .featured_scroll {
        width: 600px;
    }

    .section_title {
        font-size: 15px;
    }

    .profile_margin {
        margin: 0 4em;
    }

    .peopleSection {
        width: 90%;
    }

    .header_left {
        margin-right: 0;
    }

    .header_right {
        margin-left: 5%;
    }
}

@media (max-width: 1290px) {
    .featured_scroll {
        width: 550px;
    }

    .header_left {
        margin-left: 50px;
    }

    .header_right {
        margin-right: 50px;
        margin-left: 4%;
    }

    .left_feed {
        width: 70%;
    }

    .location_search {
        width: 30%;
    }

    .location_search_text {
        font-size: 13px;
    }

    .search_bar_start {
        width: 30%;
    }

    .search_bar_end {
        width: 20%;
    }

    .deals {
        width: 88%;
        width: fit-content;
    }
}

@media (max-width: 1250px) {
    .left_feed {
        width: 70%;
    }

    .header_search {
        width: 180px;
    }

    .header_search > form > input {
        width: 50%;
    }

    .header_left {
        margin-right: 0;
        margin-left: 35px;
    }

    .header_right {
        margin-left: 25px;
        gap: 20px;
        margin-right: 35px;
    }
}

@media (max-width: 1140px) {
    .header_nav {
        display: block;
    }

    .left_feed {
        width: 100%;
    }

    .Featured {
        width: 45em;
    }

    .right_feed {
        display: none;
    }

    .header_option {
        display: none;
    }

    .chat_container__3CA0T {
        display: none;
    }

    .featured_scroll {
        width: 650px;
    }

    .dropdownLanguage {
        bottom: 55px !important;
        left: unset !important;
        top: unset !important;
    }

    .header_left {
        margin-left: 60px;
    }

    .header_right {
        margin-right: 60px;
        margin-left: 15px;
    }

    .header_avatar {
        margin-left: 15px;
    }

    .deals {
        width: 80px;
    }
}

@media (max-width: 1136px) {
    .header_left {
        width: 100%;
        margin-right: 0;
    }

    .left_feed {
        width: 100%;
    }

    /* .location_search {
    width: 40%;
  } */

    .search_bar_box {
        width: 100%;
        /* background-color: red; */
    }

    .search_bar_start {
        width: 10%;
        font-size: 0;
    }

    .search_bar_end {
        width: 30%;
    }

    .down_arrow_icon {
        cursor: pointer;
        height: 20px;
        margin-left: 8px;
    }

    .down_search_icon {
        cursor: pointer;
        height: 20px;
    }

    .down_deals_icon {
        cursor: pointer;
        height: 20px;
    }

    .search_icon {
        cursor: pointer;
        height: 20px;
    }

    .mic_icon {
        cursor: pointer;
        height: 20px;
    }

    .deals {
        width: 80px;
        /* border: 1px solid black; */
        border-radius: 4px;
    }
}

@media (max-width: 1100px) {
    .mobileheader {
        display: none !important;
    }

    .header {
        display: flex;
    }

    .noResult {
        width: 70vw;
    }

    .right_feed {
        display: none;
    }

    .header_left {
        margin-left: 40px;
        gap: 20px;
    }

    .header_right {
        margin-right: 40px;
    }

    .search_bar_start {
        width: 24%;
    }
}

@media (max-width: 1032px) {
    .cover_feed {
        width: 100%;
    }

    .profile_margin {
        margin: 0;
    }

    .left_side_bar {
        display: none;
    }

    .feed,
    .Featured {
        width: 60em;
    }

    .featured_scroll {
        width: 700px;
    }

    .leftbar_hamburger_view {
        display: block;
    }

    .left_feed {
        display: block;
    }

    .search_feed {
        margin: 0px 20px;
    }

    .mains {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 950px) {
    .header_left {
        margin-left: 10px;
    }

    .header_right {
        margin-right: 10px;
    }
}

@media (max-width: 800px) {
    .mains_search {
        margin-top: 20px;
        margin-bottom: 100px;
    }

    .deals {
        z-index: 30;
    }

    .header {
        position: inherit;
    }

    .header_left {
        margin-left: 40px;
    }

    .header_search {
        margin-left: -50px;
        margin-top: 0px;
    }

    .header_left .main_logo {
        display: none;
    }

    .header_search img {
        display: block;
    }

    .header_left {
        position: absolute;
    }

    .profile_icon {
        align-items: center;
    }

    .dropdown_menu {
        position: absolute;
        left: 280px;
    }

    .dropdown_menu ul {
        margin: 0px;
    }

    .header_avatar {
        position: absolute;
        left: 10px;
        top: 20px;
    }

    .header_nav {
        position: absolute;
        right: 50px;
    }

    .followers {
        font-size: 14px;
    }

    .search_feed {
        margin: 0px 20px;
        margin-bottom: 50px;
    }

    .feed,
    .Featured {
        width: 50em;
    }

    .feed {
        margin-top: 20px;
    }

    .leftbar_hamburger_view {
        margin-top: -150px;
    }

    .inner_invitation {
        /* flex-direction: column; */
    }

    .flex_sp {
        /* width: 100%; */
        /* justify-content: flex-start; */
    }

    .invitationSection {
        width: 100%;
    }

    .icon_text_large {
        font-weight: 600;
        font-size: 14px;
        margin-left: 8px;
        letter-spacing: 0px;
    }

    .header_left {
        width: 100%;
        margin-left: 0px;
    }

    .location_search {
        width: 10%;
        margin-left: 8%;
    }

    .location_search_text {
        font-size: 10px;
    }

    .search_bar_box {
        width: 57%;
    }

    .search_bar_start {
        width: 18%;
        font-size: 0;
    }

    .search_bar_end {
        width: 30%;
    }

    .icon_container_assignment {
        height: 28px;
        width: 28px;
    }

    .inner_icon_assignment {
        height: 19px;
        width: 19px;
    }

    .header_avatar {
        margin-left: 0px;
    }
}

@media (max-width: 720px) {
    .suggestion_card_div {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (max-width: 690px) {
    .header_left {
        gap: 20px;
    }

    .location_search {
        width: 5%;
        margin-left: 70px;
    }

    .location_search_text {
        display: none;
    }
}

@media (max-width: 670px) {
    .header {
        position: inherit;
    }

    .feed,
    .Featured {
        width: 35em;
    }

    .inner_invitation {
        padding: 10px 10px;
    }

    .leftbar_hamburger_view {
        margin-top: -160px;
    }

    .coverPicture {
        width: 100%;
        height: 120px;
    }

    #template_modal {
        width: 80%;
    }
}

@media (max-width: 620px) {
    .search_bar_box {
        width: 50%;
    }
}

@media (max-width: 580px) {
    .suggestion_card_div {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .modal_date > div {
        width: 100%;
    }

    .modal_date > div:nth-child(1) {
        margin-right: 0px;
    }
}

@media (max-width: 550px) {
    .header {
        position: inherit;
    }

    .header_search {
        width: 240px;
    }

    .header_search form input {
        width: 160px;
    }

    .search_bar_box {
        width: 45%;
    }
}

@media (max-width: 538px) {
    .header {
        position: inherit;
    }

    .profile_icon {
        margin-left: 0px;
    }
}

@media (max-width: 520px) {
    .header {
        position: inherit;
    }

    .feed,
    .Featured {
        width: 30em;
    }

    .wrap_100 {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .mobileheader {
        display: flex;
    }
}

@media (max-width: 462px) {
    .header {
        position: inherit;
    }

    .header_search {
        width: 176px;
    }

    .header_search form input {
        width: 100px;
    }
}

@media (max-width: 432px) {
    .header {
        position: inherit;
    }

    .feed,
    .Featured {
        width: 28em;
    }

    .deals {
        width: 80px;
        /* border: 1px solid black; */
        border-radius: 4px;
        background-color: #fff;
    }

    .header_search {
        width: 130px;
    }

    .header_search form input {
        width: 52px;
    }

    #hamburger_card {
        width: 100%;
        padding: 30px 30px 30px 30px;
    }

    .inner_invitation {
        flex-direction: column;
    }

    .inner_invitation > div {
        width: 100%;
    }

    .flex_sp {
        justify-content: flex-start;
    }

    .flex_sp > div {
        width: 100%;
    }

    .connect_btn {
        width: 100%;
        margin-top: 10px;
    }

    .connect_btn_fade {
        width: 100%;
        margin: 10px 0;
    }

    .connect_btn_fade_remove {
        width: 100%;
        margin: 10px 0;
    }
}

@media (max-width: 480px) {
    .header {
        position: inherit;
    }

    .feed,
    .Featured {
        width: 25em;
    }

    .down_arrow_icon {
        margin-left: 2px;
    }

    .searchbar_input {
        margin-left: 8px;
    }

    .header_search {
        width: 130px;
    }

    .header_search form {
        width: 60px;
    }

    .header_left {
        gap: 5px;
    }

    .location_search {
        height: 26px;
        margin-left: 56px;
    }

    .search_bar_start {
        width: 15%;
        margin-left: 25px;
    }

    .search_bar_box {
        height: 30px;
        width: 55%;
        padding: 4px 8px;
    }

    .down_arrow_icon {
        height: 15px;
    }

    .down_search_icon {
        height: 15px;
    }

    .down_deals_icon {
        height: 15px;
    }

    .search_icon {
        height: 15px;
    }

    .mic_icon {
        height: 15px;
    }

    .dash_icon {
        height: 15px;
        margin-right: 2%;
    }

    .header_nav {
        position: absolute;
        right: 20px;
    }
}

@media (max-width: 356px) {
    .header {
        position: inherit;
    }

    .feed,
    .Featured {
        width: 22em;
    }

    .header_search {
        width: 97px;
    }

    .header_search form input {
        width: 16px;
    }
}

/* Dropdown Button */

@media (max-width: 340px) {
    .search_bar_start {
        display: none;
    }

    .search_bar_box {
        height: 30px;
        width: 40%;
    }

    .down_arrow_icon {
        height: 15px;
    }

    .down_search_icon {
        height: 15px;
    }

    .down_deals_icon {
        height: 15px;
    }

    .search_icon {
        height: 15px;
    }

    .mic_icon {
        height: 15px;
        display: none;
    }

    .dash_icon {
        height: 15px;
        margin-right: 2%;
        display: none;
    }

    .header_right {
        margin-left: 250px;
    }

    .header_nav {
        display: block;
        right: 20px;
    }

    .profile_icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 50px;
    }
}