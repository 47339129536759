.main {
  margin-top: 10px;
  width: 100%;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_left{
  display: flex;
  flex-direction: column;
}

.heading {
  display: block;
  font-weight: 700;
  font-size: 1.125rem;
  padding-bottom: 10px;
}
.details {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: 400;
}

.progress_container {
  display: flex;
  align-self: center;
  border: 1px solid #E5E5E5;
  border-radius: 33px;
  padding: 8px 10px;
  align-items: center;
  margin-left: auto;
  width: 50%;
}
.progress_container > span {
  font-weight: 600;
  font-size: 1.125rem;
  padding: 0 10px;
  white-space: nowrap;
}

.details span {
  padding-right: 10px;
  padding-bottom: 16px;
}

.content {
  width: 75%;
}

.section_title {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Montserrat";
}

.icon {
  width: 1.125rem;
  margin-right: 8px;
}



.section_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.lecture_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.lecture_count {
  margin-left: auto;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: .875rem;
  color: #000000;
}

.lecture_title {
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #000000;
}

.lecture_title_completed {
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #00d498;

}

.duration {
  margin-left: auto;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 300;
  color: #000000;
}

.duration_completed {
  margin-left: auto;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 700;
  color: #00d498;

}

@media (max-width:1000px){
  .header {
    display: block;
    margin-bottom:10px;
  }

  .progress_container {
    display: flex;
    width: 96%;
  }
}

@media (max-width:500px){
  .header {
    display: block;
    margin: 10px 0px;
  }
  
  .header_left{
    display: block;
  }
  
  .progress_container {
    display: flex;
    width: 94%;
  }

  .details {
    font-size: 12px;
  }
}

