.classModal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: fit-content;
  padding-top: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.thumbnailArea {
  width: 550px;
  height: 125px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-left: 25px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
}
.inputField {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}
.textarea {
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 5px;
  outline: none;
  margin-left: 15px;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.inputField1 {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 105px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 15px 0px 15px 15px;
}
.classHrDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

/* .drop {
  display: flex;
  align-items: center;
}  */

input.inputField1::placeholder {
  top: 10px;
  position: absolute;
}
.inputLabel {
  font-size: 13px;
  margin: 0 10px;
  padding-left: 1rem;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: normal;
}

.saveDetailsButton {
  border: 1px #00d498 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #00d498;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 24px;
}

.saveDetailsButton:hover {
  background-color: #00d498;
  color: white;
  margin-bottom: 24px;
  /* border: 1px #236ae8 solid; */
}
.fileUpload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.uploadBox input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.thumbnailPic {
  width: 550px;
  height: 125px;
}
@media (max-width: 800px) {
  .inputField, .inputField1 {
    width: 85%;
  }
  .thumbnailArea {
    width: 85%;
  }
  .thumbnailPic {
    width: 100%;
  }
}
