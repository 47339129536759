.Home-Wrapper {
  padding-top: 180px;
  padding-left: 200px;
  padding-right: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  flex-direction: column;
  /* background-image: url("../../../animation/Hero/bg1.svg"); */
  /* background-image: url("../../../animation/Hero/bgDesktop.svg"); */
  background-size: contain;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.subLayout {
  padding-left: 180px;
  padding-right: 180px;
}

.Home-Section1 > h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}

.Home-Section1 > p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 175%;
  text-align: center;
  color: #000000;
}

.Home-Section1-buttons {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.Home-section1-btn1,
.Home-section1-btn2 {
  background: #000000;
  border: 2px solid #000000;
  border-radius: 40px;
  width: 180px;
  height: 50px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.Home-section1-btn2 {
  border: 2px solid #000000;
  border-radius: 40px;
  background-color: transparent;
  color: #000000;
}

.Home-section2 {
  margin-top: 48px;
  margin-bottom: 48px;
  width: 85%;
  height: 85%;
}

.Home-section3 {
  position: relative;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home-section3-group {
  background: #f8f8f8;
  border-radius: 32px;
  display: flex;
  gap: 12px;
  padding: 40px;
}
.Home-section3-stable {
  /* z-index:5; */
  width: 40%;
}
.Home-section3-stable > h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 105%;
}
.Home-section3-stable > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 166.5%;
  margin-top: 12px;
}
.Home-section3-adCards {
  /* z-index: 4; */
  position: absolute;
  width: 78%;
  background: transparent;
  overflow-x: scroll !important;
  left: 42%;
  display: flex;
  gap: 12px;
}
/* .vertical-line{
    height:10;
    width: 20px;
} */

.Home-section3-adCards::-webkit-scrollbar {
  height: 0px;
}

.Home-section4 {
  height: auto;
  position: relative;
  margin-top: 48px;
  width: 100%;
  text-align: center;
}
.Home-section4-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin-left: -260px;
  background-position: right;
  background-image: url("../../../animation/Hero/bg2.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.Home-section4-video h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 215%;
  color: #000000;
}

.Home-section4-video > p {
  margin-top: 24px;
  width: 100%;
  height: 400px;
  border-radius: 56px;
  background: grey;
}

.moentize-video-div {
  width: 100%;
}

.video {
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 30px;
  /* margin: 30px auto;  */
  width: 100% !important;
  height: 100% !important;
  padding: 0;
}
.video > video {
  border-radius: 20px;
}
.play-btn {
  position: absolute;
  top: -3px;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home-section5 {
  margin-top: 120px;
  position: relative;
}
.Home-section5-bg {
  position: absolute;
  width: 100vw;
  height: 75vh;
  background-position: contain;
  background-repeat: no-repeat;
  margin-left: -260px;
  background-repeat: no-repeat;
}
.bg-1 {
  background: url("../../../animation/Hero/bg3.svg");
  background-position: left;
}

.bg-2 {
  background: url("../../../animation/Hero/bg5.svg");
  background-position: right;
}

.bg-3 {
  background: url("../../../animation/Hero/bg5.svg");
  background-position: left;
}

.bg-4 {
  background: url("../../../animation/Hero/bg5.svg");
  background-position: right;
}

.bg-5 {
  background: url("../../../animation/Hero/bg6.svg");
  background-position: right;
}
.Home-section6 {
  width: 100%;
}

.Home-section6 > h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 196.5%;
  text-align: center;
}

.Home-section6-cards {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 100%;
  width: 100%;
}

.Home-section6-cards-part1 {
  max-width: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.Home-section6-cards-part2 {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Hero-section7 {
  height: 60vh;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: url("../../../animation/Hero/subsBg.svg"); */
  /* background-position: left; */
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
  margin-top: 60px;
}

.Hero-section7 > h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 148%;
  text-align: center;
}

.Hero-section7-subs {
  display: flex;
  text-align: center;
  margin-top: 40px;
  gap: 12px;
  width: 90%;
  height: 64px;
}

.Hero-section7-subs > input {
  width: 70%;
  padding: 16px 40px;
  background: #ffffff;
  border: 1px solid #a6a6a6;
  border-radius: 40px;
  font-size: 20px;
}

.Hero-section7-subs > input:focus {
  outline: none !important;
  border: 1px solid black !important;
}

.Hero-section7-subs > input::placeholder {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #a6a6a6;
  text-align: center;
}

.Hero-section7-subs > button {
  background: #000000;
  text-transform: uppercase;
  width: 240px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  cursor: pointer;
  border: none;
  color: #ffffff;
  border-radius: 40px;
}

.homePhoto {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: left;
  margin-top: 60px;
}

@media only screen and (max-width: 1350px) {
  .Home-Wrapper {
    padding-top: 180px;
    padding-left: 180px;
    padding-right: 180px;
  }
  .Home-section5-bg {
    margin-left: -180px;
  }
  .Home-section4-wrapper {
    margin-left: -180px;
  }
  .Hero-section7 {
    padding: 0 40px;
  }
}
/* ############################ Responsive 1024 ############################ */

@media only screen and (max-width: 1024px) {
  .Home-Wrapper {
    padding-top: 210px;
    padding-left: 130px;
    padding-right: 130px;
  }

  .subLayout {
    padding-left: 130px;
    padding-right: 130px;
  }

  .Home-Section1 > h1 {
    font-weight: 700;
    font-size: 56px;
    margin-bottom: 12px;
  }

  .Home-Section1 > p {
    font-size: 18px;
    line-height: 160%;
  }

  .Home-Section1-buttons {
    margin-top: 12px;
    gap: 12px;
  }

  .Home-section1-btn1,
  .Home-section1-btn2 {
    width: 180px;
    height: 50px;
  }

  .Home-section2 {
    height: 100%;
    width: 100%;
  }

  .Home-section3 {
    height: auto;
  }

  .Home-section3-group {
    gap: 12px;
    padding: 30px;
  }

  .Home-section3-stable > h4 {
    font-size: 30px;
    line-height: 105%;
  }
  .Home-section3-stable > p {
    font-size: 14px;
    line-height: 166.5%;
    /* margin-top:12px; */
  }

  .Home-section4 {
    height: auto;
    margin-top: 44px;
  }

  .Home-section4-video h3 {
    font-size: 32px;
    line-height: 180%;
  }

  .Home-section4-video > p {
    margin-top: 12px;
    height: 400px;
    border-radius: 56px;
  }

  .moentize-video-div {
    width: 100%;
  }

  .video {
    border-radius: 20px;
    margin-top: 30px;
    padding: 0;
  }

  .play-btn {
    position: absolute;
  }

  .Home-section5 {
    margin-top: 24px;
  }

  .Home-section6 {
    width: 100%;
  }

  .Home-section6 > h4 {
    font-size: 40px;
    line-height: 196.5%;
    text-align: center;
  }

  .Home-section6-cards {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    max-width: 100%;
    width: 100%;
  }

  .Home-section6-cards-part1 {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Home-section6-cards-part2 {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Hero-section7 {
    height: auto;
    padding: 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  .Hero-section7 > h1 {
    font-size: 48px;
    line-height: 148%;
  }

  .Hero-section7-subs {
    display: flex;
    margin-top: 32px;
    width: 90%;
    height: 70px;
  }

  .Hero-section7-subs > input {
    width: 70%;
    font-size: 18px;
    padding: 16px 40px;
  }

  .Hero-section7-subs > input::placeholder {
    font-size: 20px;
    line-height: 29px;
  }

  .Hero-section7-subs > button {
    width: 250px;
    font-size: 20px;
    line-height: 29px;
  }
}

@media only screen and (max-width: 950px) {
  .Home-Wrapper {
    /* padding-top: 150px; */
    padding-left: 80px;
    padding-right: 80px;
  }

  .subLayout {
    padding-left: 80px;
    padding-right: 80px;
  }

  .Home-section5-bg {
    margin-left: -100px;
    height: 60vh;
  }
  .Home-section4-wrapper {
    margin-left: -100px;
  }

  .Home-Section1 > h1 {
    font-size: 48px;
    margin-bottom: 12px;
  }

  .Home-Section1 > p {
    font-size: 18px;
    line-height: 180%;
  }

  .Home-Section1-buttons {
    margin-top: 12px;
    gap: 12px;
  }

  .Home-section1-btn2,
  .Home-section1-btn1 {
    width: 180px;
    height: 50px;
  }
  .Home-section2 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .Home-section3 {
    height: auto;
  }

  .Home-section3-group {
    gap: 12px;
    padding: 25px;
  }

  .Home-section3-stable {
    width: 60%;
  }
  .Home-section3-stable > h4 {
    font-size: 32px;
  }
  .Home-section3-adCards {
    left: 60%;
  }

  .Home-section4 {
    height: auto;
    margin-top: 44px;
  }

  .Home-section4-video h3 {
    font-size: 32px;
    line-height: 180%;
  }

  .Home-section4-video > p {
    margin-top: 12px;
    height: 400px;
  }

  .moentize-video-div {
    width: 100%;
  }

  .video {
    margin-top: 30px;
    padding: 0;
  }

  .play-btn {
    position: absolute;
  }

  .Home-section5 {
    margin-top: 24px;
  }

  .Home-section6 {
    width: 100%;
  }

  .Home-section6 > h4 {
    font-size: 40px;
    line-height: 196.5%;
  }

  .Home-section6-cards {
    margin-top: 24px;
    gap: 12px;
    max-width: 100%;
    width: 100%;
  }

  .Home-section6-cards-part1 {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Home-section6-cards-part2 {
    max-width: 50%;
  }

  .Hero-section7 {
    height: auto;
  }
  .Hero-section7 > h1 {
    font-size: 48px;
    line-height: 148%;
  }

  .Hero-section7-subs {
    margin-top: 32px;
    width: 90%;
    height: 55px;
  }

  .Hero-section7-subs > input {
    width: 70%;
    padding: 16px 40px;
    font-size: 16px;
  }

  .Hero-section7-subs > input::placeholder {
    font-size: 20px;
    line-height: 29px;
  }

  .Hero-section7-subs > button {
    width: 250px;
    font-size: 20px;
    line-height: 29px;
  }
}

@media only screen and (max-width: 650px) {
  .Home-Wrapper {
    padding-top: 180px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .subLayout {
    padding-left: 50px;
    padding-right: 50px;
  }
  .Home-section5-bg {
    margin-left: -80px;
    height: 80vh;
  }
  .Home-section4-wrapper {
    margin-left: -80px;
  }

  .Home-Section1 > h1 {
    font-size: 40px;
    margin-bottom: 12px;
  }

  .Home-Section1 > p {
    font-size: 18px;
    line-height: 180%;
  }

  .Home-Section1-buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    gap: 12px;
  }

  .Home-Section1-buttons-link {
    width: 100%;
  }

  .Home-section1-btn2,
  .Home-section1-btn1 {
    width: 100%;
    height: 50px;
  }

  .Home-section2 {
    margin-top: 48px;
    margin-bottom: 48px;
    height: 100%;
    width: 100vw;
  }

  .Home-section3 {
    height: auto;
  }

  .Home-section3-group {
    display: flex;
    gap: 12px;
    padding: 20px;
  }

  .Home-section3-stable {
    /* z-index:5; */
    width: 60%;
  }
  .Home-section3-stable > h4 {
    font-size: 24px;
  }
  .Home-section3-stable > p {
    /* font-size: */
  }
  .Home-section3-adCards {
    left: 60%;
  }

  .Home-section4 {
    height: auto;
    margin-top: 34px;
  }

  .Home-section4-video h3 {
    font-size: 24px;
    line-height: 180%;
  }

  .Home-section4-video > p {
    margin-top: 12px;
    height: 400px;
  }

  .moentize-video-div {
    width: 100%;
  }

  .video {
    margin-top: 30px;
    padding: 0;
  }

  .play-btn {
    position: absolute;
  }

  .Home-section5 {
    margin-top: 24px;
  }

  .Home-section6 {
    width: 100%;
  }

  .Home-section6 > h4 {
    font-size: 32px;
    line-height: 120%;
  }

  .Home-section6-cards {
    flex-direction: column;
    gap: 12px;
  }

  .Home-section6-cards-part2 {
    max-width: 100%;
  }

  .Hero-section7 {
    height: auto;
    margin-top: 44px;
  }
  .Hero-section7 > h1 {
    font-size: 32px;
    line-height: 148%;
  }

  .Hero-section7-subs {
    display: flex;
    margin-top: 32px;
    width: 100%;
    height: 55px;
  }

  .Hero-section7-subs > input {
    width: 70%;
    padding: 8px 24px;
  }

  .Hero-section7-subs > input::placeholder {
    font-size: 16px;
    line-height: 29px;
  }

  .Hero-section7-subs > button {
    width: 200px;
    font-size: 16px;
    line-height: 0;
  }
}

@media only screen and (max-width: 480px) {
  .Home-Wrapper {
    /* padding-top: 150px; */
    /* background: url("../../../animation/Hero/phoneBg.svg"); */
    padding-left: 30px;
    padding-right: 30px;
  }

  .subLayout {
    padding-left: 30px;
    padding-right: 30px;
  }

  .Home-section5-bg {
    margin-left: -30px;
    height: 90vh;
  }
  .Home-section4-wrapper {
    margin-left: -30px;
  }

  .Home-Section1 > h1 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .Home-Section1 > p {
    font-size: 12px;
    line-height: 150%;
  }

  .Home-Section1-buttons {
    margin-top: 24px;
    gap: 12px;
  }

  .Home-Section1-buttons button:nth-child(1),
  .Home-Section1-buttons button:nth-child(2) {
    width: 100%;
    height: 46px;
    font-size: 12px;
  }

  .Home-section2 {
    margin-top: 48px;
    margin-bottom: 48px;
    height: 100%;
    width: 100vw;
  }

  .Home-section3 {
    height: auto;
  }

  .Home-section3-group {
    display: flex;
    gap: 12px;
    padding: 12px;
  }
  .Home-section3-stable > h4 {
    font-size: 16px;
    line-height: 105%;
  }
  .Home-section3-stable > p {
    font-size: 8px;
    line-height: 166.5%;
  }

  .Home-section4 {
    height: auto;
    margin-top: 24px;
  }

  .Home-section4-video h3 {
    font-size: 20px;
    line-height: 215%;
  }

  .Home-section4-video > p {
    margin-top: 12px;
    height: 400px;
  }

  .moentize-video-div {
    width: 100%;
  }

  .video {
    margin-top: 30px;
    padding: 0;
  }

  .play-btn {
    position: absolute;
  }

  .Home-section5 {
    margin-top: 60px;
  }

  .Home-section6 {
    width: 100%;
    margin-top: 12px;
  }

  .Home-section6 > h4 {
    font-size: 20px;
    line-height: 120%;
  }

  .Home-section6-cards {
    flex-direction: column;
  }

  .Home-section6-cards-part2 {
    max-width: 100%;
  }

  .Hero-section7 {
    height: auto;
    padding: 0 10px;
    margin-top: 60px;
  }
  .Hero-section7 > h1 {
    font-size: 24px;
    line-height: 148%;
  }

  .Hero-section7-subs {
    margin-top: 12px;
    gap: 4px;
    width: 100%;
    height: 27px;
  }

  .Hero-section7-subs > input {
    width: 75%;
    padding: 0px 12px;
    font-size: 8px;
  }

  .Hero-section7-subs > input::placeholder {
    font-size: 8px;
    line-height: 15px;
  }

  .Hero-section7-subs > button {
    width: 95px;
    font-size: 8px;
    line-height: 0;
  }
}

@media only screen and (max-width: 400px) {
}
