.nav {
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: space-between;
  background-color: transparent;
  text-align: center;
  align-items: center;
  width: 100vw;
  z-index: 5;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)); */
  box-sizing: border-box;
  padding: 10px 200px;
  transition: 0.2s ease-in-out;
}
.nav-active {
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: space-between;
  background-color: white;
  text-align: center;
  align-items: center;
  width: 100%;
  z-index: 5;
  box-sizing: border-box;
  padding: 2px 100px;
  transition: 0.2s ease-in-out;
}
.logo-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo {
  height: 50px;
  width: auto;
}
.navlink {
  color: black;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  font-weight: 400;
  transition: 0.2s ease-in-out;
}
.navlink > a {
  margin-left: 20px;
}
.navlink-active {
  color: #9c9c9c;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  font-weight: 400;
  transition: 0.2s ease-in-out;
}
.navlink-active > a {
  margin-left: 20px;
  transition: 0.2s ease-in-out;
}
.signin {
  padding: 15px 45px !important;
  /* background-color: white; */
  background-color: black;
  border-radius: 25px;
  /* color: #00d498; */
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}
.signin:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}
.signin-header {
  text-decoration: none;
}
.join-header {
  text-decoration: none;
  border: 1px solid black;
  font-weight: 700;
  padding: 15px 45px !important;
  background-color: transparent;
  border-radius: 25px;
  text-transform: uppercase;
  color: black;
}
.join-header:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  text-transform: uppercase;
}
.join-header-active {
  text-decoration: none;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px 45px !important;
  background-color: white;
  border-radius: 25px;
  border: 1px solid black;
}
.join-header-active:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}
.signin-active {
  padding: 15px 45px !important;
  background-color: black;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 25px;
  color: white;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}
.signin-active:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}

/* ----------------------------Mobile nav----------------------- */

.nav-mobile {
  position: fixed;
  z-index: 5;
  display: none;
}
.nav-icon {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 20px rgba(85, 85, 85, 0.1);
  padding: 5px 0;
}
.menu_icon {
  width: 30px;
  height: auto;
}
.nav-icon :nth-child(1) {
  margin-left: 40px;
  width: 150px;
  height: auto;
}
.nav-icon :nth-child(2) {
  margin-right: 40px;
  width: 50px;
  height: auto;
}

.side-nav {
  position: absolute;
  right: -300px;
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 10px 20px rgba(85, 85, 85, 0.16);
  transition: right 0.3s ease-in-out;
  padding-top: 30px;
}
.side-nav-active {
  right: 0 !important;
  transition: right 0.3s ease-in-out;
}
.side-nav-links > div {
  padding: 20px 0;
  font-size: 16px;
  font-weight: 500;
}
.side-nav-links > div > a {
  text-decoration: none;
  color: black;
}

.language-header {
  text-decoration: none;
  padding: 15px 10px 15px 45px !important;
  background-color: transparent;
  /* border-radius: 25px; */
  color: white;
}
.langTextDiv {
  align-items: center;
  margin: auto;
}
.language-div {
  display: flex;
  cursor: pointer;
  position: relative;
}
.language-div-dark {
  display: flex;
  color: #00d498;
  color: black;
  cursor: pointer;
  position: relative;
}
.togglerLang {
  position: relative;
}
.dropdownLanguage {
  width: 100px;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  position: absolute;
  top: 80%;
  left: 40%;
  opacity: 1;
  background-color: #fff;
  z-index: 10;
}

.dropdownList {
  list-style: none;
  padding: 0;
}

.inactiveList {
  margin-top: 6;
  color: #555555;
  cursor: "pointer";
}

.activeList {
  margin-top: 6;
  color: #00d498;
  cursor: "pointer";
}

/* ############################ Responsive 1350 ############################ */
@media only screen and (max-width: 1350px) {
  .nav,
  .nav-active {
    padding: 5px 100px;
  }
}
/* ############################ Responsive 1024 ############################ */
@media only screen and (max-width: 1024px) {
  /* .nav,
  .nav-active {
    display: none;
  } */
  .nav-mobile {
    display: block;
  }

  .dropdownLanguage {
    top: 90% !important;
    left: 0% !important;
  }
}

@media (max-width: 900px) {
  .nav-active {
    padding: 5px 40px;
  }
}

@media (max-width: 450px) {
  .nav-active {
    padding: 5px 20px;
  }
}
