.comingSoon {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-transform: uppercase;
  padding-top: 60px;
  padding-left: 40px;
  padding-bottom: 30px;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}

.bottomContainer {
  padding-left: 40px;
  padding-bottom: 10px;
  padding-right: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.bottom_details {
  display: flex;
}

.bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.outerContainer {
  background: #ffffff;
  border-radius: 10px;
  max-width: 537px;
  position: relative;
}

.close {
  width: 21px;
  height: 21px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #171616;
  display: grid;
  place-items: center;
  cursor: pointer;
}
