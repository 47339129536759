.btn_label {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

.menu_item_label {
  color: #000;
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
}

.menu_icon {
  width: 1.1875rem;
  height: 1.1875rem;
  margin-right: 0.5rem;
}

@media (max-width:500px){
  .btn_label {
    font-size: 8px;
    font-weight: 700;
  }

}