.ClassHr {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.upcoming_container {
}

.top_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_container {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0px solid #e5e5e5;
  border-color: #e5e5e5;
  /* box-shadow: 0px 0px 20px #3f3f411a; */
  border-radius: 10px;
  padding: 8px 0;

  /* Card shadow */
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
}

.bottom_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #00d498;
  font: normal normal 600 13px/16px Montserrat;
  cursor: pointer;
}

/* 
margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px #3f3f411a;
 */
