.list_container {
  /* display: flex;
  justify-content: space-between; */
}

.assignment_title {
  margin: 0 5px 0 18px;
  font-size: 12px;
}

.title_and_date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.assignment_due_date {
  margin: 0px 18px 0 5px;
  font-size: 11px;
}
.ClassHr {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
