.container {
  background-color: #fff;
  border-radius: 0 0 14px 14px;
  min-height: 250px;
}

.classModal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.inputField {
  border-radius: 25px;
  width: 550px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.inputField1 {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 105px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.inputField {
  border-radius: 25px;
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
  padding: 0 20px;
}
/* .drop {
  display: flex;
  align-items: center;
}  */

input.inputField1::placeholder {
  top: 10px;
  position: absolute;
}
/* .inputLabel {
    font-size: 13px;
    margin-bottom: 10px;
    padding-left: 1rem;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: normal;
  }   */

.inputLabel {
  font-size: 16px;
  margin: 10px 0 10px 20px;
  font-weight: normal;
}

.saveDetailsButton {
  border: 1px #00d498 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #00d498;
  /* margin-left: 20px; */
  margin-top: 20px;
  cursor: pointer;
}

.saveDetailsButton:hover {
  background-color: #00d498;
  color: white;
  /* border: 1px #236ae8 solid; */
}
.hidden {
  display: none;
}
.formFields {
  padding: 0 30px;
  box-sizing: border-box;
}
