.assignment_submission_list_container {
  display: flex;
  /* justify-content: space-between; */
  border-top: 1px solid #e5e5e5;
  padding: 13px 15px;
  align-items: center;
  font-family: Montserrat;
}

.assignment_status_div {
  width: 45%;
  display: flex;
  justify-content: center;
}
.quiz_status_div{
  width: 44%;
  display: flex;
  justify-content: center;
}
.assignment_grade_div {
  width: 34%;
  display: flex;
  justify-content: center;

}

.assignment_status {
  /* color: #7b7b7b; */
  color: black;
  font-size: 15px;
}

.assignment_marks {
  /* text-decoration: underline; */
  /* color: #7b7b7b; */
  color: black;
  white-space: pre;
  /* padding-bottom: 0.25px; */
  border-bottom: 1px solid #A9A9A9;
  width: fit-content;
}

.assignment_due_date {
  color: black;
  font-size: 14px;
  font-weight: 500;
  width: 25%;
  display: flex;
  justify-content: center;
}
.column_width {
  width: 35%;
}
.three_column {
  display: flex;
  width: 100%;
}
.three_column_assignment {
  display: flex;
  width: 100%;
}
.checkbox_round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #767676;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.checkbox_round:checked {
  background-color: #00d498;
  border: 1px solid #00d498;
}
.checkbox_round:disabled {
  background-color: grey;
  border: 1px solid grey;
}
.full_list {
  display: flex;
  align-items: center;
  width: 100%;
}

.action_div{
  display: flex;
  justify-content: center;
  align-items: center;
  width:12%;
}

.assign_text{
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #00D498;
  cursor: pointer;
}



@media (max-width: 800px) {
  .three_column {
    flex-direction: column;
    width: 50%;
    /* justify-content: space-between; */
  }
  /* .full_list{
    flex-direction: column;
    align-items: center;
  } */
  .three_column > div {
    width: 100%;
    margin-bottom: 5px;
    margin-left: 5px;
  }
   .column_width {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .three_column_assignment {
    flex-direction: column;
    width: 60%;
  }
  
  .three_column_assignment > div {
    width: 100%;
    margin-bottom: 5px;
    margin-left: 5px;
  }  

   .column_width {
    width: 50%;
  }
}


