.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.left_container {
  position: relative;
  width: 100%;
}

.left_container > img {
  width: 100%;
  height: 100%;
  max-height: 100px;
  object-fit: cover;
}

.left_container > span {
  font-weight: 500;
  font-size: 0.5rem;
  padding: 2px 5px;
  background-color: #D9D9D9;
  color: #555555;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.8;
}

.right_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-left: 12px;
  align-items: flex-start;
}

.title {
  margin-bottom: 10px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 0.875rem;
  color: #000;
  line-height: 1.0625rem;
}

@media (max-width:1000px){
  .main{
    display: block;
  }
}
