.container {
  background-color: #fef7f8;
  display: flex;
  color: black;
  font-weight: bold;
  padding: 12px;
  max-width: 80%;
  flex-shrink: 1;
  margin-bottom: 7px;
  word-wrap: break-word;
  margin-left: 5px;
  width: fit-content;
}

.outer {
  width: 100%;
}

.fileContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
  align-items: center;

  p {
      margin-bottom: 0;
  }
}