.group_rightsidebar {
  border-radius: 10px;
  text-align: center;
  height: fit-content;
  /* margin-top: 210px; */
  margin-top: 15px;
  /* width:300px;
    margin-right: 70px;
    margin-left: 20px; */
}
.groupRightsidebar_stats {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.groupRightsidebar_stat {
  text-align: left;
  padding: 20px 15px 20px 15px;
}
.groupRightsidebar_inner_stat {
  flex-direction: "row";
  justify-content: space-between;
  background-color: "red";
}
.groupHr {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.groupRightsidebar_stat1 {
  text-align: left;
  padding: 20px 15px 20px 15px;
}

.classinputdiv {
  margin-top: 5px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  /* width:200px; */
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  opacity: 1;
  /* margin-left: 25px; */
  margin-bottom: 15px;
}

.classinputdiv > form > input {
  font: italic normal medium 13px/16px Montserrat;
  outline: none;
  border: none;
  background: none;
  width: 190px;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  /* margin-right: 2px; */
}

.groupinviteButton {
  border: 1px #00d498 solid;
  background-color: white;
  border-radius: 2rem;
  height: 40px;
  font: normal normal 600 12px/15px Montserrat;
  font-weight: 600;
  color: #00d498;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
}

.groupinviteButton:hover {
  background-color: #00d498;
  color: white;
}
