.classModal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: fit-content;
  padding-top: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.inputField {
  border-radius: 5px;
  width: 550px;
  margin-left: 15px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c248 solid;
  padding-left: 20px;
}

.inputField1 {
  border-radius: 5px;
  width: 550px;
  margin-left: 15px;
  height: 155px;
  outline: none;
  border: 1px #c2c2c248 solid;
  padding: 15px 0px 15px 15px;
}

input.inputField1::placeholder {
  top: 8px;
  position: absolute;
}

.inputLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin-bottom: 8px;
  padding-left: 1rem;
  /* margin-left: 20px; */
  margin-top: 8px;
  font-weight: normal;
  color: gray;
}

.saveDetailsButton {
  border: 1px #1c3d72 solid;
  background-color: #1c3d72;
  border-radius: 0.3rem;

  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin-right: 30px;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  justify-content: center;
}

.saveDetailsButton:hover {
  background-color: #1c3d72;
  color: white;
  margin-bottom: 24px;
  /* border: 1px #236ae8 solid; */
}

@media (max-width: 800px) {
  .inputField,
  .inputField1 {
    width: 85%;
  }

  .thumbnailArea {
    width: 85%;
  }

  .thumbnailPic {
    width: 100%;
  }
}

:root {
  --switches-bg-color: green;
  --switches-label-color: white;
  --switch-bg-color: white;
  --switch-text-color: black;
}

body {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* font-size: 12px; */
}

/* resize font-size on html and body level. html is required for widths based on rem */
@media screen and (min-width: 1024px) {
  html,
  body {
    /* font-size: 24px; */
  }
}

@media screen and (max-width: 1024px) {
  html,
  body {
    /* font-size: 16px; */
  }
}

@media screen and (max-width: 600px) {
  html,
  body {
    /* font-size: 12px; */
  }
}

/* a container - decorative, not required */

/* container for all of the switch elements 
  - adjust "width" to fit the content accordingly 
*/
.switches_container {
  width: 7rem;
  position: relative;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  padding: 0;
  position: relative;
  background: var(--switches-bg-color);
  /* line-height: 3rem; */
  line-height: 1.5rem;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-right: 25px;
  font-size: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.input_public_private {
  visibility: hidden;
  position: absolute;
  top: 0;
  font-size: 8px;
}

/* labels for the input (radio) boxes - something to click on */
.switches_container .input_label_monthly {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--switches-label-color);
  font-size: 8px;
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
*/
.switch_wrapper {
  position: absolute;
  top: 1px;
  bottom: 0;
  width: 45%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  font-size: 8px;
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 10px;
  background: var(--switch-bg-color);
  height: 100%;
  font-size: 8px;
}

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.switch .switch_public {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 8px;
}

/* slide the switch box from right to left */
.input_public_private:nth-of-type(1):checked ~ .switch_wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.input_public_private:nth-of-type(2):checked ~ .switch_wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.input_public_private:nth-of-type(1):checked
  ~ .switch_wrapper
  .switch
  .switch_public:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.input_public_private:nth-of-type(2):checked
  ~ .switch_wrapper
  .switch
  .switch_public:nth-of-type(2) {
  opacity: 1;
}
