.RightSidebar {
  /* position: sticky; */
  /* top: 80px; */
  /* flex: 0.23; */
  border-radius: 10px;
  text-align: center;
  height: fit-content;
  /* margin-right: 80px; */
  margin-top: 15px;
  /* max-width: 290px; */
}

.Rightsidebar_mycontent {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.side {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}

.Rightsidebar_icon1 {
  background: #00d498 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 5px;
}

.Rightsidebar_icon2 {
  color: #929292;
}

.Rightsidebar_mycontent > h3 {
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  /* font-weight: 600;
  margin-right: 80px; */
}

.Rightsidebar_stats {
  margin-top: 10px;
  padding: 20px 5px;
  /* margin-bottom: 10px; */
  border: 1px solid lightgray;
  border-color: white;
  border-radius: 10px;
  /* box-shadow: 0 0 10px 5px #d3d3d3; */
  box-shadow: 0px 0px 20px #3f3f411a;
  text-align: left;
}

.Rightsidebar_stat {
  margin-top: 10px;
  /* display: flex; */
  /* justify-content: space-evenly; */
  margin-bottom: 10px;
  /* margin-left: 9px; */
}

.Rightsidebar_statNumber {
  /* padding-left: 10px; */
  cursor: pointer;
  margin-top: 8px;
}

.Rightsidebar_statNumber2 {
  padding-left: 10px;
}

/* .Rightsidebar_statNumber:hover {
  color: var(--secondary-main);
  text-decoration: underline;
} */

.Rightsidebar_stat > p {
  color: #000;
  font: normal normal normal 13px Montserrat;
  text-align: left;
}

.Rightsidebar_details {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.Rightsidebar_details > h4 {
  font-size: 14px;
  /* font-weight: 600; */
  color: #00d498;
  text-align: left;
}
.Hr1 {
  /* height: 2px !important;
  width: 280px !important;
  color: #555555 !important;
  opacity: 0.3; */
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.sidebar_stat2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #00d498;
  font: normal normal 600 13px/16px Montserrat;
  text-transform: uppercase;
  /* border-top: 1px solid #d6d6d6; */
}
