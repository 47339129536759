.fourZeroFour {
  text-align: center;
  line-height: 1.5;
  position: absolute;
  z-index: 100000000000;
  background-color: white;
  width: 100vw;
  height: 100%;
}
.fourZeroFourOops {
  color: var(--secondary-main);
  font-size: 100px;
  font-weight: 600;
  margin: 0;
}

.fourZeroFourText {
  font-size: 24px;
  font-weight: 600;
}

.fourZeroFourButton {
  padding: 20px 50px;
  border-radius: 30px;
  border: none;

  background-color: var(--secondary-main);
  cursor: pointer;
  color: white;
}

.fourZeroFourImage {
  height: 50vh;
  margin-top: 10px;
}
