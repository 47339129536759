.sale_page_sale_card_container {
  /* height: 350px; */
  padding-bottom: 5px;
  height: 100%;
  width: 270px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px 0px,
    rgba(0, 0, 0, 0.2) 0px 0px 2px 0px;
  overflow: hidden;
  position: relative;
}

.sale_page_sale_card_image {
  width: 100%;
  height: 100%;
}

.sale_page_sale_card_mid {
  padding-left: 10px;
  padding-right: 10px;
}

.sale_page_sale_time_date {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  gap: 20px;
  font-size: 13px;
  margin-top: 20px;
  color: #666666;
}

.sale_page_sale_date,
.sale_page_sale_time {
  display: flex;
  gap: 5px;
}

.sale_page_sale_title {
  margin-top: 5px;
  padding-left: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.sale_page_sale_mode {
  margin-top: 5px;
  padding-left: 10px;
  font-size: 13px;
  color: #666666;
}

.sale_page_sale_mode {
  margin-top: 5px;
  padding-left: 10px;
  font-size: 13px;
  color: #666666;
}

.sale_page_sale_location_span {
  display: flex;
  gap: 2px;
  align-items: flex-start;
}

.sale_page_sale_category_span {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.sale_page_sale_card_bottom {
  /* position: absolute; */
  /* bottom: 0px; */

  margin-top: 10px;
}

.sale_page_sale_interested {
  /* position: absolute; */
  /* bottom: 65px; */
  margin-top: 12px;
  margin-bottom: 5px;

  font-size: 13px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sale_page_sale_interested_button_cont {
  /* position: absolute; */
  /* bottom: 20px; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.sale_page_sale_interested_button {
  background: var(--primary-gradient);
  width: 90%;
  border: none;
  height: 40px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 800px) {
  .sale_page_sale_card_container {
    /* height: 340px; */
    /* width: 270px; */
    width: 380px;
    margin-left: 5px;
  }
}

@media (max-width: 590px) {
  .sale_page_sales_bottom {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }

  .sale_page_sale_card_container {
    /* height: 340px; */
    /* width: 270px; */
    width: 96%;
  }
}

@media (max-width: 400px) {
  .sale_page_sales_bottom {
    grid-template-columns: auto;
    justify-content: space-around;
  }

  /* .sale_page_sale_card_container {
        height: 340px;
        width: 270px;

    } */
}
