.profilePic {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  object-fit: cover;
}

.container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  gap: 8px;
  flex-wrap: wrap;
  margin-left: 5px;
  justify-content: space-between;
  margin-right: 60px;
}

.textareaContainer {
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 5px;
}

.textarea {
  resize: none;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
}

.dropDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.hashtag {
  color: #00d498;
  padding-left: 10px;
  padding-top: 11px;
  font-size: small;
  cursor: pointer;
}
.textareaDisc {
  border-radius: 15px;
  padding: 10px;
  width: 540px;
  padding-bottom: 5px;
  outline: none;
  margin-left: 15px;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.crossSvg {
  margin-top: -2px;
  margin-left: -2px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(271deg)
    brightness(108%) contrast(102%);
}
