.main {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.main span {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  /* line-height: 15px; */
}

.main > img {
  margin-right: 10px;
  width: 1rem;
  height: 1rem;
}
