.assignment_feed {
  padding: 24px;
  padding-top: 0px;
  padding-bottom: 16px;
}

.noAssignmentOrQuiz {
  padding: 24px;
  padding-top: 40px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat";
  color: #7b7b7b;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7b7b7b;
  font-size: 14px;
  justify-content: center;
}
.loader{
  text-align: center;
  margin-top: 20px;
}
