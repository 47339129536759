.container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  width: 257px;
  height: 350px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.container:hover {
  cursor: pointer;
}

.imageContainer {
  background: #00d498 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  height: 140px;
}

.textContainer {
  padding: 0 20px;
}

.subjectImage {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 1;
  object-fit: cover;
}

.subjectText {
  text-align: left;
  line-height: 19px;
  font-size: 16px;
  font-family: "Montserrat";
  letter-spacing: 0px;
  color: #555555;
  margin-bottom: 30px;
}

.gradeText {
  text-align: left;
  line-height: 19px;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0px;
  color: #555555;
  font-family: "Montserrat";
  opacity: 1;
  margin-bottom: 10px;
}

.nameText {
  text-align: left;
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  font-family: "Montserrat";
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}



/* New Card Css */
.groupCardContainer {
  /* background: red; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 30px;
  width: 300px;
  height: 400px;
  margin-bottom: 20px;
  margin-right: 20px;
  overflow: hidden;

}

.groupCardImageContainer {
  background: #00d498 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  height: 140px;
}



.groupCardImage {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 1;
  object-fit: cover;
}

.groupCardTextContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: flex-start;
  height: 40%;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 10px;
  /* gap: 10px; */
}

.groupCardName {
  font-size: 22px;
  font-weight: 700;
}

.groupCardCode {
  font-size: 12px;
  color: gray;
  /* margin-top: 5px; */
}

.groupCardVisiblity {
  font-size: 10px;
  padding: 4px;
  border-radius: 4px;
  background-color: #a6a6a61f;
  color: gray;
  display: flex;
  align-items: center;
  gap: 4px;
  /* margin-top: 5px; */

}


/* .groupCardDescContainer {} */

.groupCardDescriptionTitle {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 2px;
}

.groupCardDescription {
  font-weight: 500;
  font-size: 15px;
  color: gray;
}


.groupCardButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.groupCardButton {
  background: #068e46;
  text-align: center;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 15px;
  gap: 5px;
  cursor: pointer;
  margin-top: 10px;
}