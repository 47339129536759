.view_assignment_modal{
    background-color: white;
    width: 65%;
    padding: 20px;
    border-radius: 20px;

}

.view_assignment_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.view_assignment_description{
    font-size: 14px;
    color: #707070;
    margin: 20px 10px;
    word-wrap: break-word;
    word-break: break-all;
}

.view_assignment_attachment{
    margin-bottom: 20px;
}

  
.view_assignment_button{
    display: flex;
    justify-content: flex-end;
}
.dropdown{
    width: fit-content;
    cursor: pointer;
    margin-left: 15px;
    height: 45px;
}
.bottom_btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.threeDotsMenu{
    height: 45px;
}


@media (max-width: 1099px) {
    .view_assignment_modal{
        width: 80%;
    }
}

@media (max-width: 768px) {
    .view_assignment_modal{
        width: 90%;
    }
}