.whitecoverBackground {
  height: 30px;
  width: 30px;
  position: relative;
  left: 20%;
  bottom: 90%;
  border-radius: 100%;
  background-color: white;
  border: 1px whitesmoke solid;
  cursor: pointer;
}

.profilecoverImg {
  border-radius: 10px;
}

/* .whitecoverBackground {
    height: 30px;
    width: 30px;
    position: relative;
    left: 20%;
    bottom: 90%;
    border-radius: 100%;
    background-color: white;
    border: 1px whitesmoke solid;
    cursor: pointer;
  }
  .profilecoverImg {
    border-radius:10px;
  } */

/* @media (max-width: 1099px) {
  .cover {
    width: 100%;
  }
}*/
@media (min-width: 768px) {
  .cover {
    width: 100%;
  }
}

/* @media (min-width:768px){
   img.profilecoverImg{
     width: 100% !important;
   } 
  } */

/* Top left text */
.topLeft {
  position: absolute;
  top: 8px;
  left: 16px;
}

.button1 {
  display: flex;
  border: 1px solid;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  background-color: #ef3f69;
  color: white;
  font-weight: 700;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  padding: 6.5px;
  width: unset;
  align-items: center;
}

.button1:hover {
  background-color: #fff;
  color: #ef3f69;
  border: 1px solid #ef3f69;
}

.selectionOption {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.selectionOption>img {
  margin-right: 10px;
}

.selectionOption:hover {
  background-color: #fff;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.Hr3 {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.dropdownMenu1 {
  margin-top: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  margin-left: -80px;
  position: absolute;
  background-color: white;
  min-width: 180px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  display: block;
  right: 0;
  padding: 7px 0;
}

.dropdownMenu1 a:hover {
  background-color: #ddd;
}

.dropdownMenu1 a {
  color: black;
  text-decoration: none;
  display: block;
}

@media (max-width: 768px) {
  .mediaQueryBreak {
    display: block;
    margin-bottom: 2px;
  }

  .subDash {
    display: none;
  }

  .createText {
    display: none;
  }

  .button1 {
    /* width:fit-content; */
    /* width: 35px;
    height: 35px; */
    border-radius: 50%;
    justify-content: center;
    padding: 6.5px;
    width: unset;
    align-items: center;
  }
}