.player_container{
  position: relative;
}

.play_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
}

.play_btn_icon > img {
  height: 62px;
  cursor: pointer;
}

