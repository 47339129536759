.drawer_header{
  display: flex;
  align-items: center;
  font-weight: 600;
}

.section_title {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Montserrat";
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  margin: 8px;
}

.lecture_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.lecture_title {
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #555555;
}

.lecture_title_active {
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #000;

}

.lecture_title_completed {
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #00D498;
}

.duration {
  margin-left: auto;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 300;
  color: #555555;
}

.duration_active {
  margin-left: auto;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 300;
  color: #000000;
}

.duration_completed {
  margin-left: auto;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 300;
  color:#00D498;
}

@media (max-width: 600px) {
  .slider_icon{
    border: 1px white #000;
    border-radius: 0 4px 4px 0 !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 1px !important;
    margin-top: 25px !important;
  }
}