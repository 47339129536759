.main {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  border-width: 8px 0px 8px 0px;
  border-style: solid;
  border-color: #00d498;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  background-color: #fff;
  box-shadow: 0px 4px 21px 0px #00000040;
  max-width: 40vw;
  height: fit-content;
  padding: 10px;
  border-radius: 16px;
}

.main > img {
  width: 51%;
  margin: auto;
  /* padding: 15px 0; */
}

.description {
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.0625rem;
  text-align: center;
  /* width: 60%; */
  color: #7a7a7a;
  margin: auto;
  padding: 0 5px;
}

.heading {
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  color: #000000;
}

.description2 {
  font-family: "Montserrat";
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 2.0625rem;
  text-align: center;
  color: #7a7a7a;
  padding: 0 5px;
}

.btn_div {
  margin: auto;
  padding: 15px 0;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  /* Styles for phones go here */
  .main {
    max-width: 83vw;
    height: 446px;
  }

  .heading {
    font-size: 1.25rem;
    line-height: 20px;
  }

  .description2 {
    font-size: 0.9375rem;
    line-height: 1.0rem;
  }

  .btn_div {
    margin: auto;
    padding: 18px 32px;
    cursor: pointer;
  }

  .Button{
    width: 131px;
    height: 41px;
    padding: 18px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media (min-width:601px) and (max-width:1000px){
  .main {
    max-width: 50vw;
  }
  .main > img {
    width: 40%;
  }
  .heading {
    font-size: 1.5rem;
  }
  .description2 {
    font-size: 1.1rem;
  }
}