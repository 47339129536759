.group_rightsidebar {
  border-radius: 10px;
  text-align: center;
  height: fit-content;
  margin-top: 15px;
  /* width:300px; */
  /* margin-right: 70px; */
  /* margin-left: 20px; */
}

.groupRightsidebar_stats {
  margin-bottom: 10px;
  border: 1px solid rgb(75, 64, 64);
  border-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.groupRightsidebar_stat {
  text-align: left;
  padding: 0px 15px;
}

.groupRightsidebar_inner_stat {
  flex-direction: "row";
  justify-content: space-between;
  background-color: "red";
}

.groupHr {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.groupRightsidebar_stat1 {
  text-align: left;
  padding: 0 15px;
}

.classinputdiv {
  margin-top: 5px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  /* width:200px; */
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  opacity: 1;
  /* margin-left: 25px; */
  margin-bottom: 15px;
}

.classinputdiv>p {
  font: italic normal medium 13px/16px Montserrat;
  outline: none;
  border: none;
  background: none;
  width: 190px;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  cursor: pointer;
  flex: 0.9;
  /* margin-right: 2px; */
}

/* height: 40px;
    border-radius:25px;
    margin-top: 5px;
    /* width: 240px; */
/* border: 1px solid #C2C2C2;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding-left: 20px;
    outline: none;  */

.groupinviteButton {
  /* border: 1px var(--secondary-main) solid; */
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #068e46;
  border-radius: 0.8rem;
  border: none;
  width: 100%;
  /* height: 40px; */
  font-size: 18px;
  font-weight: 600;
  /* color: var(--secondary-main); */
  color: white;
  margin-top: 10px;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
}

.groupinviteButton:hover {
  background-color: white;
  border: 1px solid #068e46;
  color: #068e46;
}

.class_agenda {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 20px;
  padding: 10px;
}

.class_agenda_details {
  display: flex;
  margin-bottom: 5px;
}

.class_agenda_details>img {
  height: 23px;
  width: 26px;
  margin-right: 10px;
  cursor: pointer;
}

.class_agenda_details>button {
  border: 1px var(--secondary-main) solid;
  background-color: white;
  width: 57px;
  height: 27px;
  border-radius: 2rem;
  font-size: 12px;
  font-weight: 600;
  color: var(--secondary-main);
  cursor: pointer;
}

.class_agenda_details>button:hover {
  background-color: var(--secondary-hover);
  color: white;
}

.class_agenda_details>h4 {
  font: normal normal medium 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.class_agenda>h4 {
  font: normal normal normal 14px/20px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.Classsidebar_mycontent {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.Classsidebar_mycontent>h3 {
  font-size: normal normal medium 16px/19px Montserrat;
}

.Classsidebar_stats {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.ClassHr {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.sidebar_stat1>p {
  display: flex;
  align-items: center;
  margin-left: 25%;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #00d498;
  font: normal normal 600 13px/16px Montserrat;
}

.sidebar_stat1 {
  cursor: pointer;
}

.Classsidebar_icon1 {
  background: #00d498 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1 !important;
}

.Class_side {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}