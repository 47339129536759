.main_container {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 90px;
}
.back_btn {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: fit-content;
}
.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* width: 96%; */
  margin: auto;
  /* width: 140vh; */
}

.assignment_list {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  margin-top: 15px;
  max-height: 400px;
  overflow-y: scroll;
  width: 96%;
  margin: auto;
  /* margin-bottom: 85px; */
}

.assignment_class_container {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #3f3f411a;
  padding: 20px 10px;
  margin: auto;
  margin-bottom: 30px;
  width: 94%;
}

.assignment_class_name {
  border-radius: 20px;
  border: 1px solid #858585;
  padding: 10px 20px;
  width: fit-content;
}

.assignment_class_head {
  color: #858585;
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .main_container {
    margin-top: 0;
  }
  .assignment_class_container {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .assignment_list {
    max-height: 300px;
    margin-bottom: 77px;
  }
}

.loader_style{
  text-align: center;
   margin-top: 200px
}