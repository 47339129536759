.sale_page_sidebar_parent {
    margin-top: 120px;
    /* background: #000; */
    /* width: 30%; */
    /* background: #000; */
}


.sale_page_sidebar_gohome {
    /* width: 15%; */
    padding: 10px;
    margin-right: 40px;
    margin-left: 40px;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
}

.sale_page_sidebar_gohome:hover {
    background: #6f76812b;
}

.sale_page_sidebar_ul {
    padding-left: 40px;
}

.sale_page_sidebar_li {
    list-style: none;
    /* height: 40px; */
    font-size: 16px;
    padding: 10px;
    margin-right: 40px;
    /* margin-left: 30px; */
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
}

.sale_page_sidebar_li:hover {
    background: #6f76812b;
}

.sale_page_sidebar_list_title {
    color: gray;
    margin-left: 30px;
}



@media (max-width: 800px) {
    .sale_page_sidebar_parent {
        display: none;
    }

}