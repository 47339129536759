.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 12px;
  width: 500px;
  padding: 20px;
  text-align: center;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.closeIcon {
  cursor: pointer;
  font-size: 20px;
}

.templateContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  justify-content: center;
  margin-top: 10px;
  justify-items: center;
}

.templateCard {
  padding: 30px 20px;
  background-color: rgb(219, 219, 219);
  border-radius: 8px;
  height: 200px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.templateCard:hover {
  transform: scale(1.05);
}

.templateImage {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  box-shadow: rgb(150, 150, 150) 0px 10px 15px;
}

/* #f33070 Background - Initially Hidden */
.templateOverlay {
  background: #2bb14d;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Show #f33070 Background on Hover */
.templateCard:hover .templateOverlay {
  opacity: 1;
}

.templateTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.templatePrice {
  font-size: 12px;
  margin-bottom: 10px;
}

.viewButton,
.useButton,
.setButton {
  border: none;
  padding: 4px 16px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  margin: 2px;
}

.viewButton {
  background: #2bb14d;
  border-radius: 20px;
  border: 2px solid white;
  color: white;
}

.useButton,
.setButton {
  background: white;
  color: #2bb14d;
  border: 2px solid #2bb14d;
  border-radius: 20px;
}

.setButton {
  cursor: not-allowed !important;
}

.viewTemplateBtn {
  text-align: center;
  width: 100%;
}

.template_modal .scroll_div_class {
  padding: 2rem;
}

.previewtemplateContainer {
  height: 15rem;
  width: 100%;
  padding: 1rem;
}

.previewTemplateCard {
  width: 200px;
  height: 100%;
  margin: 0 auto;
}

@media (min-width: 720px) {
  .previewTemplateCard {
    height: 100%;
    width: 300px;
  }

  .previewtemplateContainer {
    height: 20vw;
    width: 100%;
    padding: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .templateCard {
    width: 180px;
    height: 225px;
  }
}

@media (max-width: 767px) {
  .templateCard {
    width: 120px;
    height: 160px;
    padding: 20px;
  }
  .templateTitle {
    font-size: 10px;
  }
}

@media (max-width: 430px) {
  .templateCard {
    width: 100px;
    height: 140px;
    padding: 15px;
  }
  .templateTitle {
    font-size: 10px;
  }
}

@media (max-width: 344px) {
  .templateContainer {
    grid-template-columns: repeat(1, 1fr);
  }
  .templateCard {
    width: 150px;
    height: 170px;
    padding: 15px;
  }
}

