
.price{
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    padding-bottom:24px;
    text-align: left;
    text-transform: uppercase;
}

.priceText{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    padding-bottom:24px;
    text-align: left;
    padding-left:2px;
}

@media only screen and (max-width: 1000px) {
    .priceText{
        padding-bottom:10px;
    }
}

@media only screen and (max-width: 800px) {
    
    .price{
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        padding-bottom:8px;
    }
    
    .priceText{
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        padding-bottom:8px;
    }
    
    
}

@media (max-width:500px)
{
    .priceText{
        font-size: 0.8rem;
        line-height: 19px;
    }
    .price{
        padding-bottom: 0px;
    }
}