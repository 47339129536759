.user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1rem;
    border-bottom: 2px solid rgb(228, 228, 228);
    p {
        margin-bottom: 0;
    }
}

.highlight {
    background-color: #f5f7f6;
}

.user:hover {
    background-color: #f5f7f6;
    cursor: pointer;
}

.userPillContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1rem;
    flex-wrap: wrap;
}

.userPill {
    background-color: var(--secondary-main);
    padding: .5rem 1rem;
    border-radius: var(--bs-border-radius-pill);
    display: flex;
    gap: 5px;

    p {
        margin-bottom: 0;
        color: white;
    }
}

.profilePic {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    object-fit: cover;
}

.userList {
    display: flex;
    flex-direction: column;
    border-top: var(--bs-border-width) var(--bs-border-style)
        var(--bs-border-color);
}