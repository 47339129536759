.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: "100%";
  height: 40px;
  box-sizing: border-box;
}

.button-cover {
  /* height: 100px; */
  /* margin: 20px; */
  /* background-color: #fff; */
  /* box-shadow: 0 10px 20px -8px #c5d6d6; */
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  /* content: counter(button-counter); */
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 16px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.btnToggle {
  position: relative;
  top: 50%;
  width: 120px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.btnToggle.r,
.btnToggle.r .layer {
  border-radius: 100px;
}

.btnToggle.b2 {
  border-radius: 18px;
}

.checkbox_toggle {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: green;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 10 */
#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
  position: absolute;
  top: 4px;
  width: 53px;
  height: 10px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  border-radius: 15px;
  transition: 0.3s ease all;
}

#button-10 .knobs:before {
  content: "";
  left: 4px;
  background-color: #fff;
  padding: 14px;
}

#button-10 .knobs:after {
  content: "Private";
  right: 4px;
  color: #fff;
}

#button-10 .knobs span {
  display: inline-block;
  left: 4px;
  color: #000;
  z-index: 1;
}

#button-10 .checkbox_toggle:checked + .knobs span {
  color: #fff;
}

#button-10 .checkbox_toggle:checked + .knobs:before {
  left: 62px;
  background-color: #fff;
}

#button-10 .checkbox_toggle:checked + .knobs:after {
  color: #000;
}

#button-10 .checkbox_toggle:checked ~ .layer {
  background-color: green;
}
