.name_container {
  display: flex;
  align-items: center;
}

.student_name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-right: 10px;
}

.avatar {
  margin-right: 18px; 
}
/* 
@media (max-width: 800px) {
  .name_container {
    margin-bottom: 20px;
  }  
} */

@media (max-width: 600px) {
 .name_container, .student_name, .avatar{
    display:none;
  }
}