.modalHeading {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.comingSoon {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 60px;
  padding-left: 40px;
  padding-bottom: 30px;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}
.groupinviteButton {
  border: 1px #00d498 solid;
  background-color: white;
  border-radius: 2rem;
  width: 70%;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #00d498;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 26px;
  margin-top: 26px;
}
.groupinviteButton:hover {
  background-color: #00d498;
  color: white;
}
.bottomContainer {
  padding-left: 40px;
  padding-bottom: 10px;
  padding-right: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.bottom_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.outerContainer {
  background: #ffffff;
  border-radius: 10px;
  max-width: 537px;
  position: relative;
}

.close {
  width: 21px;
  height: 21px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #171616;
  display: grid;
  place-items: center;
  cursor: pointer;
}
