.groupinviteButton {
  border: 1px #00d498 solid;
  background-color: white;
  border-radius: 2rem;
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #00d498;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 26px;
  margin-top: 26px;
}
.groupinviteButton:hover {
  background-color: #00d498;
  color: white;
}
.inviteContent {
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  padding: 6px;
  margin-left: 10px;
}
.invitedStudentSerialNum {
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
}
.invitedStudent {
  text-align: left;
  font: italic normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 0.5;
}
.tableHeading {
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
}

.inviteHeading {
  text-align: left;
  font: normal normal medium 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 20px;
  padding-top: 30px;
}
.groupHeading {
  text-align: left;
  font: normal normal medium 28px/34px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.listElement {
  text-align: left;
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  padding-bottom: 20px;
}
.inviteHr {
  border: none;
  border-left: 4px solid #00d498;
  margin-right: 8px;
}
.instructionsContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #5555551a;
  flex-direction: row;
  border-radius: 10px;
  height: 271px;
}
.greyContainer {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  height: 271px;
}
.inviteList .listElement::before {
  content: "\2022";
  color: #00d498;
  font-weight: bold;
  display: inline-block;
  width: 1.09em;
  margin-left: -1.085em;
}
