.container {
  min-width: 220px;
  min-height: 165px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px 1px #888888;
  border-radius: 15px;
  margin-left: 8px;
  margin-top: 8px;
  margin-right: 8px;
  position: relative;
}

.innerContainer {
  position: relative;
  align-items: center;
  text-align: center;
}

.contentcontainer {
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #000;
  padding-top: 15px;
}

.following {
  padding: 7px;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #000;
}

.pic {
  width: 55px;
  height: 55px;
  /* position: absolute;
    top: -30px;
    left: auto; */
  border-radius: 100%;
  /* transform: translate(-25px); */
  margin-top: -28px;
}

.button {
  /* display: flex; */
  flex-grow: 0;
  padding: 16px 30px 16px 30px;
  border: 1px solid var(--secondary-main);
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: var(--secondary-main);
  text-transform: uppercase;
  max-width: 107px;
  margin: 20px;
}

.button:hover {
  background-color: var(--secondary-hover);
  color: #fff;
}
