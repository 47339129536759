.conversationCardContainer {
    width: 100%;
    border-bottom: 2px solid rgb(228, 228, 228);
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-right: 3.5px solid transparent;
}

.conversationCardContainer:hover {
    background-color: #f5f7f6;
    cursor: pointer;
    border-right: 3.5px solid var(--secondary-main);
}

.selectedChat {
    background-color: #f5f7f6;
    border-right: 3.5px solid var(--secondary-main);
}

.image {
    min-height: 3.5rem;
    min-width: 3.5rem;
    background-position: center;
    background-size: cover;
}

.message-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.message-wrapper {
    font-size: 0.8rem;
}

.profilePic {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    object-fit: cover;
}