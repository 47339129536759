.container {
    background-color: #fff;
    height: 350px;
    /* transition: all 3s; */
    display: flex;
    width: 350px;
    flex-direction: column;
    padding: 0 1px 10px 7px;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
    /* padding-top: 0;
    padding-right: 1px ;
    padding-left: 7px; */
    pointer-events: all;
}

.middleContainer {
    /* display: flex; */
    /* flex-grow: 0; */
    padding-top: 5px;
    padding-right: 5px;
    overflow-y: auto;
    flex-direction: column;
    margin-top: auto;
}

.inputContainer {
    display: flex;
    border-radius: 20px;
    gap: 10px;
    order: 2;
    /* margin-top: auto; */
    align-items: center;
    padding-right: 5px;
}

.innerInputContainer {
    /* background-color: #eee; */
    display: flex;
    border-radius: 20px;
    gap: 5px;
    vertical-align: middle;
    align-items: center;
    padding-left: 5px;
    flex-grow: 1;
    border: 2px solid #eee;
    padding-right: 5px;
}

.imagesContainer {
    display: flex;
    gap: 10px;
}

.greyImage {
    filter: invert(87%) sepia(0%) saturate(1129%) hue-rotate(253deg)
        brightness(84%) contrast(85%);
}

/* .addContainer {
    background-color: #00d498;
    display: flex;
    align-items: center;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    margin: 0.3rem;
} */

.inputBox {
    /* background-color: #eee; */
    border: none;
    /* height: 30px; */
    display: flex;
    flex-grow: 1;
    /* margin-bottom: 5px; */
    border-radius: 20px;
    padding: 10px;
    color: #5e5e5e;
}

.inputBox:focus {
    outline: none;
}

.unreadText {
    color: #8a8a8a;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #8a8a8a;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.unreadSpan {
    background: #fff;
    padding: 0 10px;
}

.middleContainer::-webkit-scrollbar {
    width: 5px;
}

.middleContainer::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
}

.middleContainer::-webkit-scrollbar-thumb:hover {
    background: #e6e6e6;
}

.middleContainer::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
}

.addContainer {
    cursor: pointer;
}

.addContainer:hover {
    background: rgba(238, 238, 238, 0.3);
    border-radius: 50%;
}

.fileInput {
    display: none;
}

.fileContainer{
    padding: 1rem;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: bold;
    font-size: .9rem;
    border-top: var(--bs-border-width) var(--bs-border-style)
        var(--bs-border-color);
}