.Class_Sidebar {
  text-align: center;
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  height: fit-content;
  z-index: 5;
  /* width:250px; */
  /* margin-left: 70px; */
}
.classList:hover {
  cursor: pointer;
}

.Classsidebar_mycontent {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.Classnavbar_menu1 {
  z-index: 11;
  height: 0;
  width: 24px;
}
.Class_side {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}
.live_stream {
  margin-bottom: 10;
}
.sidebar_stat1 > p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: var(--secondary-main);
  font: normal normal 600 13px/16px Montserrat;
}

.Classsidebar_icon1 {
  background: var(--primary-main) 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1 !important;
}
.dropdown_menu1 {
  width: 184px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: 20px;
  position: relative;
  right: 160px;
}

.dropdown_menu1 ul {
  list-style: none;
  padding: 3px 0px;
}

.dropdown_menu1 li {
  padding: 4px 12px;
}
.menu1 {
  text-align: left;
  font-size: 12px;
}

.navbar_menu1 {
  z-index: 11;
  height: 0;
  width: 24px;
}

.Classsidebar_icon2 {
  color: #929292;
}

.Classsidebar_mycontent > h3 {
  font-size: normal normal medium 16px/19px Montserrat;
}

.Classsidebar_stats {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.Classsidebar_stat {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 5px 5px 15px;
  cursor: pointer;
}
.Classsidebar_stat1 {
  margin-top: 10px;
  display: flex;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 5px 5px 15px;
}

.Classsidebar_stat > p {
  color: #555555;
  font: normal normal normal 14px/18px Montserrat;
}

.ClassHr {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
.video {
  height: 70px;
  width: 70px;

  border: none;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 8px;
  margin: 0 10px;
}
.live_session {
  margin-top: 20px;
  align-self: stretch;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border: 1px solid #00d498;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #00d498;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.live_session:hover {
  background-color: #00d498;
  color: #ffffff;
}

.Classsidebar_statNumber {
  text-align: left;
  margin: auto 15px;
}
.onhover {
  color: inherit;
}
.onhover:hover {
  color: #00d498;
  /* text-align: left;
  margin: auto 15px; */
}
/* .Classsidebar_statNumber:hover {
  color: #00d498;
} */

.sidebar_stat1 {
  cursor: pointer;
}

.classModal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.formFields {
  padding: 0 30px;
  box-sizing: border-box;
}
.inputLabel {
  font-size: 16px;
  margin: 10px 0 10px 20px;
  font-weight: normal;
}
.inputField {
  border-radius: 25px;
  width: 550px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.saveDetailsButton {
  border: 1px #00d498 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #00d498;
  /* margin-left: 20px; */
  margin-top: 20px;
  cursor: pointer;
}

.saveDetailsButton:hover {
  background-color: #00d498;
  color: white;
  /* border: 1px #236ae8 solid; */
}
.textarea {
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  padding-bottom: 5px;
  outline: none;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
}

@media (max-width: 500px) {
  .Classsidebar_mycontent {
    margin-top: 0;
  }
  .Class_side {
    margin-top: 10px;
  }
  .Classnavbar_menu1 {
    margin-top: 10px;
  }
}
