.app_body {
  display: flex;
  background-color: #ffffff;
  font-family: "Montserrat";
  margin-top: 100px;
  padding-bottom: 80px;
}
@media (max-width: 990px) {
  .app_body > div > div{
    width: 100vw;
  }
  .app_body > div{
    margin: 0;
  }
}
@media (max-width: 900px) {
  .app_body > div > div > div{
    width: 100%;
  }
  .app_body > div > div > div > div > div{
    width: 100%;
  }
}
@media (max-width: 768px) {
  .col-xs-0,
  .col-sm-0,
  .col-md-0,
  .col-lg-0 {
    flex: 0 0 0;
    max-width: 0;
    padding-right: 0;
    padding-left: 0;
    display: none;
  }
}

@media (max-width: 801px) {
  .app_body {
    margin-top: 0;
  }
}
