@import "../../../../variables.css";

.container {
  padding: 10px 20px;
  gap: 30px;
  /* display: flex; */
  flex-wrap: wrap;
  padding-left: 5px;
  align-items: center;
  text-align: justify;
}

.photo {
  /* transition: all .3s; */
  height: 32px;
  cursor: pointer;
}

.rightInner {
  display: flex;
  gap: 20px;
  order: 2;
  margin-left: auto;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-right: 25px;
}

.innerBox {
  gap: 10px;
  display: flex;
  position: relative;
  overflow-x: scroll;
  width: "100%";
  flex-direction: column;
  margin-left: 40px;
}

.innerBox::-webkit-scrollbar {
  display: none;
}

.photo:hover {
  filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg)
    brightness(95%) contrast(93%);
}

.post {
  display: flex;
  padding: 8px 17px;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  justify-content: center;
  font-weight: 600;
  background-color: var(--secondary-main);
}

.post:hover {
  background-color: var(--secondary-hover);
  color: #fff;
}

.fileUpload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.uploadBox input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.dropDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
