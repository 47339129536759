.btn {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border: 1px solid #00d498;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #00d498;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: white;
}

.btn:hover {
  background-color: #00d498;
  color: #ffffff;
}
