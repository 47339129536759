.Overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: #232D4C; */
  background: #000;
  opacity: 0.5;
}

.Modal {
  z-index: 110;
  /* position: fixed; */
  position: absolute;
  top: 42%;
  background: white;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ModalContainer {
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
}

/* .NewScrollBar::-webkit-scrollbar {
    width: 7px;
    height: 7px;
   }
.NewScrollBar::-webkit-scrollbar-thumb {
background: linear-gradient(13deg, #f9d4ff 14%,#c7ceff 64%);
border-radius: 10px;
}
.NewScrollBar::-webkit-scrollbar-thumb:hover{
background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%);
}
.NewScrollBar::-webkit-scrollbar-track{
background: #ffffff;
border-radius: 15px;
box-shadow: inset 17px 0px 0px #f0f0f0;
} */
