.date_field {
  border-radius: 25px !important;
  margin-right: 20px !important;
  height: 40px !important;
  outline: none !important;
  border: 1px #c2c2c2 solid !important;
  /* padding-left: 20px !important; */
  box-sizing: border-box !important;
  width: fit-content;
  font-family: Montserrat;
  font-size: 13px;
}

@media (max-width: 780px) {
  .date_field {
    margin-right: 0px !important;

  }
}
