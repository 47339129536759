.course_card_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  /* width: 654px; */
  width: 100%;
  /* height: 427px; */
  height: fit-content;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  padding-bottom: 4px;
}

.image_container {
  background: #00d498 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  height: 191px;
  cursor: pointer;
}


.course_image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.bottom_container {
  margin: 20px;
  margin-top: 15px;
}

.payReserveButtonCont {
  /* background: red; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

/* .payReserveButton {
  width: 100% !important;
} */

.course_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 7px;
}

.description {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.price {
  color: #00d498;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
}

.text_container {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.class_tag_container {
  margin-bottom: 15px;
  /* margin-top: 8px; */
}

.course_price_for_learner {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #00d498;
}

.enroll_btn_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

}


@media(max-width: 560px) {
  .course_card_container {
    height: 440px;
  }

  .class_tag_container {
    margin-bottom: 10px;
  }
}