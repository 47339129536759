.student_count{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.student_count span{
  margin-left: 10px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}